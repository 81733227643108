import React from "react";
import { connect } from "react-redux";
import * as authRedux from "../../../../app/modules/Auth/_redux/authRedux";
import * as reportsRedux from "../../modules/reports/redux/reportsRedux";
import moment from "moment";
import { useSubheader } from "../../../../_metronic/layout/_core/MetronicSubheader";
import * as util from "../functions/util";
import {
    getFormattedDate,
    isIncompleteMonth,
    isIncompleteQuarter,
    isIncompleteYear,
    isMonthly,
    isQuarterly,
    isYearly,
} from "../functions/finalReportUtil";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import SVG from "react-inlinesvg";

const PdfFinalReport = ({ user, token, selectedDate, selectedUnits }) => {
    const subheader = useSubheader();
    const showFinalReportPdf =
        subheader.breadcrumbs && subheader.breadcrumbs[1] && subheader.breadcrumbs[1].title === "Sales";

    const getValidUrlName = getFormattedDate(selectedDate.startDate, selectedDate.endDate)
        ?.replace(/\s/g, "-")
        .toLocaleLowerCase();
    let validUrl = getValidUrlName?.replaceAll("---", "-");
    validUrl = validUrl?.replace("monthly-", "");
    validUrl = validUrl?.replaceAll(",", "");
    validUrl = validUrl?.replace("yearly-", "");
    validUrl = validUrl?.replace("quarterly-", "");

    const createURL = () => {
        let filters = "";
        if (selectedDate?.startDate) {
            filters += "&startDate=" + moment(selectedDate.startDate).format("YYYY-MM-DD");
        }
        if (selectedDate?.endDate) {
            filters += "&endDate=" + moment(selectedDate.endDate).format("YYYY-MM-DD");
        }
        if (selectedUnits && selectedUnits.length > 0) {
            const units = [];
            for (const unit of selectedUnits) {
                units.push(unit.value);
            }
            filters += "&unitId=" + units;
        }

        const baseURL = window.location.href;
        const downloadUrl = baseURL.includes("localhost")
            ? "https://localhost:8443/PopLeads"
            : window.location.protocol + "//" + window.location.host;
        let name = "final-report-" + validUrl + ".pdf";
        let authToken = token ? token : util.getCookieValue(util.getCookieName());
        let url =
            `${downloadUrl}/api/ws/download/${name}?token=${authToken}` +
            `&url=${window.location.protocol}//${window.location.host}/pdf/final-report` +
            `/?sid=${authToken}${encodeURIComponent(filters)}`;
        // console.log("url = " + url);
        window.open(url, "_blank");
        return false;
    };

    const isValidDate = (startDate, endDate) => {
        const isValidDate =
            isYearly(startDate, endDate) ||
            isMonthly(startDate, endDate) ||
            isQuarterly(startDate, endDate) ||
            isIncompleteYear(startDate, endDate) ||
            isIncompleteQuarter(startDate, endDate) ||
            isIncompleteMonth(startDate, endDate);
        return isValidDate;
    };

    const permissions = user && user.permissions ? user.permissions : null;
    return (
        <div className="pdf-mobile ml-2 mr-0">
            {permissions && permissions.FINAL_REPORTS === 1 && showFinalReportPdf && (
                <ButtonWithText
                    variant="successActive"
                    onClick={createURL}
                    disabled={!isValidDate(selectedDate.startDate, selectedDate.endDate)}
                >
                    <SVG src="/media/svg/popleads/pdf.svg" />
                </ButtonWithText>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        token: state.auth.authToken,
        selectedUnits: state.reports.selectedDepartment,
        selectedDate: state.reports.selectedDate,
        user: state.auth.user,
    };
};

const mapDispatchToProps = {
    ...authRedux.actions,
    ...reportsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PdfFinalReport);
