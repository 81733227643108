import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/popleads/modules/reports/redux/reportsRedux";
import SurveyFilterDrawer from "../drawers/SurveyFilterDrawer";
import { Dropdown } from "react-bootstrap";
import TrainerFilter from "app/popleads/modules/filters/components/TrainerFilter";
import CampaignTypeFilter from "app/popleads/modules/filters/components/CampaignTypeFilter";
import EmployeeFilter from "app/popleads/modules/filters/components/EmployeeFilter";
import { DateRangePicker } from "react-date-range";
import { customDateRanges, getDateObject } from "app/popleads/app/functions/util";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import { CustomToggleIcon } from "app/popleads/modules/activities/utils";
import styles from "../../../popleads/assets/css/design_3.0/CustomDatePicker.module.scss";

const SurveyCampaignListActions = () => {
    const { selectedTrainer } = useSelector((state) => state.reports);
    const { selectedCampaignTypeList } = useSelector((state) => state.reports);
    const { selectedEmployee } = useSelector((state) => state.reports);
    const { selectedDateSurvey } = useSelector((state) => state.reports);
    const dispatch = useDispatch();
    const [trainer, setTrainer] = useState("All Trainers");
    const [campaignTypeList, setCampaignTypeList] = useState("All Campaign Types");
    const [employee, setEmployee] = useState("All Employees");
    const [dateString, setDateString] = useState("All Time");
    const [dateSurvey, setDateSurvey] = useState([
        selectedDateSurvey.startDate
            ? {
                  startDate: selectedDateSurvey.startDate,
                  endDate: selectedDateSurvey.endDate,
                  predefinedTag: selectedDateSurvey.predefinedTag,
                  dateString: selectedDateSurvey.dateString,
                  key: "selection",
              }
            : {
                  startDate: new Date(null),
                  endDate: new Date(),
                  predefinedTag: "allTime",
                  dateString: "All Time",
                  key: "selection",
              },
    ]);

    useEffect(() => {
        if (selectedDateSurvey.startDate) {
            setDateSurvey([selectedDateSurvey]);
        }
    }, [selectedDateSurvey]);

    useEffect(() => {
        if (Array.isArray(selectedTrainer)) {
            if (selectedTrainer.length > 0) {
                setTrainer(
                    selectedTrainer.length === 1 ? selectedTrainer[0].label : `${selectedTrainer.length} Trainers`
                );
            } else {
                setTrainer("All Trainers");
            }
        }
        if (Array.isArray(selectedCampaignTypeList)) {
            if (selectedCampaignTypeList.length > 0) {
                setCampaignTypeList(
                    selectedCampaignTypeList.length === 1
                        ? selectedCampaignTypeList[0].label
                        : `${selectedCampaignTypeList.length} Campaign Types`
                );
            } else {
                setCampaignTypeList("All Campaign Types");
            }
        }
        if (Array.isArray(selectedEmployee)) {
            if (selectedEmployee.length > 0) {
                setEmployee(
                    selectedEmployee.length === 1 ? selectedEmployee[0].label : `${selectedEmployee.length} Employees`
                );
            } else {
                setEmployee("All Employees");
            }
        }
        if (selectedDateSurvey.dateString) {
            setDateString(selectedDateSurvey.dateString);
        }
    }, [selectedTrainer, selectedCampaignTypeList, selectedEmployee, selectedDateSurvey]);

    return (
        <div className="d-flex align-items-center" style={{ gap: "8px" }}>
            <Dropdown id="trainerDropdown" className="d-none d-lg-block" align="end">
                <Dropdown.Toggle as={CustomToggleIcon} id="kt_trainer_toggle">
                    <ButtonWithText
                        variant={trainer === "All Trainers" ? "checkboxInActive" : "checkboxActive"}
                        leftIcon={trainer !== "All Trainers" && "check"}
                    >
                        {trainer}
                    </ButtonWithText>
                </Dropdown.Toggle>
                <Dropdown.Menu className="my-2 p-0 dropdown-menu" style={{ width: "400px", background: "transparent" }}>
                    <TrainerFilter isMultiSelect={true} />
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown id="campaignTypeListDropdown" className="d-none d-lg-block" align="end">
                <Dropdown.Toggle as={CustomToggleIcon} id="kt_campaignTypeList_toggle">
                    <ButtonWithText
                        variant={campaignTypeList === "All Campaign Types" ? "checkboxInActive" : "checkboxActive"}
                        leftIcon={campaignTypeList !== "All Campaign Types" && "check"}
                    >
                        {campaignTypeList}
                    </ButtonWithText>
                </Dropdown.Toggle>
                <Dropdown.Menu className="my-2 p-0 dropdown-menu" style={{ width: "400px", background: "transparent" }}>
                    <CampaignTypeFilter isMultiSelect={true} />
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown id="employeeDropdown" className="d-none d-lg-block" align="end">
                <Dropdown.Toggle as={CustomToggleIcon} id="kt_employee_toggle">
                    <ButtonWithText
                        variant={employee === "All Employees" ? "checkboxInActive" : "checkboxActive"}
                        leftIcon={employee !== "All Employees" && "check"}
                    >
                        {employee}
                    </ButtonWithText>
                </Dropdown.Toggle>
                <Dropdown.Menu className="my-2 p-0 dropdown-menu" style={{ width: "400px", background: "transparent" }}>
                    <EmployeeFilter isMultiSelect={true} isLoading={true} />
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown id="datePickerDropdown" className="d-none d-lg-block" align="end">
                <Dropdown.Toggle as={CustomToggleIcon} id="kt_filter_toggle">
                    <ButtonWithText variant="checkboxActive" leftIcon="check">
                        {dateString}
                    </ButtonWithText>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    className="dropdown-menu dropdown-menu-xl px-4 py-6 dropdown-menu-anim-up"
                    style={{ width: "max-content" }}
                >
                    <DateRangePicker
                        ranges={dateSurvey}
                        className={styles.rdrDateRangePickerWrapper}
                        staticRanges={customDateRanges(new Date(null), new Date())}
                        inputRanges={[]}
                        direction="vertical"
                        onChange={(item) => {
                            const date = getDateObject(item.selection);
                            setDateSurvey([date]);
                            dispatch(actions.selectDateSurvey(date));
                        }}
                    />
                </Dropdown.Menu>
            </Dropdown>
            <SurveyFilterDrawer />
        </div>
    );
};

export default SurveyCampaignListActions;
