import React from "react";
import styled, { css } from "styled-components";
import { colors } from "./colors";
import { rgba } from "polished";
import { Heading5 } from "./Text";
import FeatherIcon from "feather-icons-react";
import { TooltipOverlay } from "../TooltipOverlay";

const buttonVariants = {
    primary: {
        background: colors.primary[900],
        hoverBackground: colors.primary[950],
        activeBackground: colors.primary[950],
        disabledBackground: colors.primary[100],
        color: colors.primary[50],
        activeColor: rgba(colors.primary[50], 0.7),
        disabledColor: colors.primary[300],
        boxShadow: rgba(colors.primary[950], 0.2),
        activeBoxShadow: "inset 0px 2px 4px #001B2633",
        borderColor: colors.primary[900],
        outlineColor: rgba(colors.primary[900], 0.5),
    },
    primaryInverted: {
        background: "transparent",
        activeBackground: colors.primary[50],
        color: colors.primary[900],
        activeColor: colors.primary[900],
        boxShadow: rgba(colors.primary[900], 0.2),
        activeBoxShadow: "inset 0px 2px 4px #001B2633",
        borderColor: colors.primary[900],
    },
    primaryBorderless: {
        background: "transparent",
        activeBackground: "transparent",
        color: colors.primary[900],
        activeColor: colors.primary[900],
        boxShadow: "none",
        hoverTextDecoration: "underline",
        svgHoverFill: colors.primary[950],
        svgActiveFill: colors.primary[900],
    },
    neutral: {
        background: colors.neutral[900],
        hoverBackground: colors.neutral[950],
        activeBackground: colors.neutral[950],
        color: colors.primary[50],
        activeColor: rgba(colors.primary[50], 0.7),
        boxShadow: rgba(colors.neutral[950], 0.2),
        borderColor: colors.neutral[900],
    },
    neutralInverted: {
        background: "#FFF",
        activeBackground: colors.neutral[50],
        color: colors.neutral[900],
        activeColor: colors.neutral[900],
        boxShadow: rgba(colors.neutral[900], 0.2),
        borderColor: colors.neutral[900],
    },
    neutralActive: {
        background: colors.neutral[50],
        activeBackground: colors.neutral[50],
        color: colors.neutral[900],
        activeColor: colors.neutral[900],
        boxShadow: rgba(colors.neutral[900], 0.2),
        borderColor: colors.neutral[900],
        borderWidth: "2px",
        padding: "0px 10px",
    },
    neutralInActive: {
        background: "#FFF",
        activeBackground: colors.neutral[50],
        color: colors.neutral[500],
        activeColor: colors.neutral[500],
        boxShadow: rgba(colors.neutral[900], 0.2),
        borderColor: colors.neutral[500],
        borderWidth: "1px",
        padding: "0px 10px",
    },
    danger: {
        background: colors.danger[900],
        hoverBackground: colors.danger[950],
        activeBackground: colors.danger[950],
        color: colors.primary[50],
        activeColor: rgba(colors.primary[50], 0.7),
        boxShadow: rgba(colors.danger[950], 0.2),
        borderColor: colors.danger[900],
    },
    dangerInverted: {
        background: "#FFF",
        activeBackground: colors.danger[50],
        color: colors.danger[900],
        activeColor: colors.danger[900],
        boxShadow: rgba(colors.danger[900], 0.2),
        borderColor: colors.danger[900],
    },
    dangerActive: {
        background: colors.danger[50],
        activeBackground: colors.danger[50],
        color: colors.danger[900],
        activeColor: colors.danger[900],
        boxShadow: rgba(colors.danger[900], 0.2),
        borderColor: colors.danger[900],
        borderWidth: "2px",
        padding: "0px 10px",
    },
    dangerInActive: {
        background: "#FFF",
        activeBackground: colors.danger[50],
        color: colors.danger[500],
        activeColor: colors.danger[500],
        boxShadow: rgba(colors.danger[900], 0.2),
        borderColor: colors.danger[500],
        borderWidth: "1px",
        padding: "0px 10px",
    },
    warning: {
        background: colors.warning[900],
        hoverBackground: colors.warning[950],
        activeBackground: colors.warning[950],
        color: colors.primary[50],
        activeColor: rgba(colors.primary[50], 0.7),
        boxShadow: rgba(colors.warning[950], 0.2),
        borderColor: colors.warning[900],
    },
    warningInverted: {
        background: "#FFF",
        activeBackground: colors.warning[50],
        color: colors.warning[900],
        activeColor: colors.warning[900],
        boxShadow: rgba(colors.warning[900], 0.2),
        borderColor: colors.warning[900],
    },
    warningActive: {
        background: colors.warning[50],
        activeBackground: colors.warning[50],
        color: colors.warning[900],
        activeColor: colors.warning[900],
        boxShadow: rgba(colors.warning[900], 0.2),
        borderColor: colors.warning[900],
        borderWidth: "2px",
        padding: "0px 10px",
    },
    warningInActive: {
        background: "#FFF",
        activeBackground: colors.warning[50],
        color: colors.warning[500],
        activeColor: colors.warning[500],
        boxShadow: rgba(colors.warning[900], 0.2),
        borderColor: colors.warning[500],
        borderWidth: "1px",
        padding: "0px 10px",
    },
    success: {
        background: colors.success[900],
        hoverBackground: colors.success[950],
        activeBackground: colors.success[950],
        color: colors.primary[50],
        activeColor: rgba(colors.primary[50], 0.7),
        boxShadow: rgba(colors.success[950], 0.2),
        borderColor: colors.success[900],
    },
    successInverted: {
        background: "#FFF",
        activeBackground: colors.success[50],
        color: colors.success[900],
        activeColor: colors.success[900],
        boxShadow: rgba(colors.success[900], 0.2),
        borderColor: colors.success[900],
    },
    successActive: {
        background: colors.success[50],
        activeBackground: colors.success[50],
        color: colors.success[900],
        activeColor: colors.success[900],
        boxShadow: rgba(colors.success[900], 0.2),
        borderColor: colors.success[900],
        borderWidth: "2px",
        padding: "0px 10px",
    },
    successInActive: {
        background: "#FFF",
        activeBackground: colors.success[50],
        color: colors.success[500],
        activeColor: colors.success[500],
        boxShadow: rgba(colors.success[900], 0.2),
        borderColor: colors.success[500],
        borderWidth: "1px",
        padding: "0px 10px",
    },
    checkboxActive: {
        background: colors.primary[50],
        activeBackground: colors.primary[50],
        color: colors.primary[900],
        activeColor: colors.primary[900],
        boxShadow: rgba(colors.primary[900], 0.2),
        borderColor: colors.primary[900],
        //height: "36px !important",
        borderWidth: "2px",
        padding: "0px 10px",
    },
    checkboxInActive: {
        background: "#FFF",
        activeBackground: colors.neutral[50],
        color: colors.neutral[500],
        activeColor: colors.neutral[500],
        boxShadow: rgba(colors.neutral[900], 0.2),
        borderColor: colors.neutral[500],
        //height: "36px !important",
        borderWidth: "1px",
        padding: "0px 10px",
    },
    circlePrimary: {
        background: colors.primary[900],
        color: "#FFFFFF",
        fontSize: "12px",
        borderRadius: "50%",
        height: "24px",
        width: "24px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "6px",
    },
};

const variantStyles = (variant = "primary") => css`
    background-color: ${buttonVariants[variant].background};
    color: ${buttonVariants[variant].color};
    border: ${buttonVariants[variant].borderWidth ?? "1.5px"} solid
        ${buttonVariants[variant].borderColor ?? buttonVariants[variant].background};
    height: ${buttonVariants[variant].height ?? "40px"};
    padding: ${buttonVariants[variant].padding ?? "0px 20px"};
    border-radius: ${buttonVariants[variant].borderRadius ?? "8px"}; /* Varsayılan değer */

    &:hover {
        background-color: ${buttonVariants[variant].hoverBackground ?? buttonVariants[variant].background};
        box-shadow: 0 4px 8px ${buttonVariants[variant].boxShadow};
        text-decoration: ${buttonVariants[variant].hoverTextDecoration};
        svg {
            fill: ${buttonVariants[variant].svgHoverFill};
        }
    }

    &:focus {
        outline: 2px solid ${buttonVariants[variant].outlineColor ?? buttonVariants[variant].background} !important;
    }

    &:active {
        background-color: ${buttonVariants[variant].activeBackground};
        box-shadow: ${buttonVariants[variant].activeBoxShadow};
        outline: none !important;
        color: ${buttonVariants[variant].activeColor};
        svg {
            fill: ${buttonVariants[variant].svgActiveFill};
        }
    }

    &:disabled {
        background-color: ${buttonVariants[variant].disabledBackground ?? buttonVariants[variant].background};
        color: ${buttonVariants[variant].disabledColor ?? buttonVariants[variant].color};
        cursor: not-allowed;
        box-shadow: none !important;
        outline: 0 !important;
    }
`;

const Buttonpl = styled.button`
    border: 0;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;

    ${({ variant }) => variantStyles(variant)}
`;

const ButtonText = styled(Heading5)`
    // İçeriği alacak olan bileşen
    white-space: nowrap;
`;

const ButtonWithText = ({ variant, children, leftIcon, icon, rightIcon, tooltip, ...props }) => {
    return (
        <Buttonpl variant={variant} {...props}>
            {tooltip ? (
                <TooltipOverlay placement="bottom" tooltip={tooltip}>
                    <ButtonText>
                        {leftIcon && (
                            <FeatherIcon
                                data-testid={`feather-${leftIcon}`}
                                icon={leftIcon}
                                size="16"
                                style={{ marginRight: "8px" }}
                            />
                        )}
                        {!icon && children}
                        {icon && <FeatherIcon data-testid={`feather-${leftIcon}`} icon={icon} size="16" />}
                        {rightIcon && (
                            <FeatherIcon
                                data-testid={`feather-${leftIcon}`}
                                icon={rightIcon}
                                size="16"
                                style={{ marginLeft: "8px" }}
                            />
                        )}
                    </ButtonText>
                </TooltipOverlay>
            ) : (
                <ButtonText>
                    {leftIcon && (
                        <FeatherIcon
                            data-testid={`feather-${leftIcon}`}
                            icon={leftIcon}
                            size="16"
                            style={{ marginRight: "8px" }}
                        />
                    )}
                    {!icon && children}
                    {icon && <FeatherIcon data-testid={`feather-${icon}`} icon={icon} size="16" />}
                    {rightIcon && (
                        <FeatherIcon
                            data-testid={`feather-${rightIcon}`}
                            icon={rightIcon}
                            size="16"
                            style={{ marginLeft: "8px" }}
                        />
                    )}
                </ButtonText>
            )}
        </Buttonpl>
    );
};

export default ButtonWithText;
