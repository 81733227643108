import ButtonWithText from "app/popleads/components/ui-kit/Button";
import ExtensionCard from "app/popleads/components/ui-kit/ExtensionCard";
import React from "react";

const ExtensionCardComponent = () => {
    const cardItems = [
        {
            icon: "msg",
            title: "",
            content: "Accounting for Non-Accountants",
            rightButtons: "",
            footer: "LVT Public | December 2024",
        },
        {
            icon: "msg",
            title: "",
            content: "AccNA Sharm El Sheikh November 24 M2",
            rightButtons: "",
            footer: "LVT Public | December 2024",
        },
        {
            icon: "msg",
            title: "",
            content: "AccNA Sharm El Sheikh November 24 M2",
            rightButtons: "",
            footer: "LVT Public | December 2024",
        },
        {
            icon: "msg",
            title: "",
            content: "AccNA Sharm El Sheikh November 24 M2",
            rightButtons: "",
            footer: "LVT Public | December 2024",
        },
    ];
    return (
        <div className="card card-custom mt-5">
            <div className="card-header">
                <h3 className="card-title">Card</h3>
            </div>
            <div className="card-body">
                <ExtensionCard
                    button={<ButtonWithText variant="circlePrimary" icon="plus" />}
                    cardTitle={"Contact"}
                    cardItems={cardItems}
                />
            </div>
        </div>
    );
};

export default ExtensionCardComponent;
