import React from "react";
import { useSelector } from "react-redux";
// import { actions } from "app/popleads/modules/reports/redux/reportsRedux";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import { NavLink } from "react-router-dom";

const SurveyEvaluationFormsActions = () => {
    const { user } = useSelector((state) => state.auth);
    const { showAddSurveyButton, surveyId, showEditSurveyButton, statusEditSurveyButton } = useSelector(
        (state) => state.survey
    );
    // const dispatch = useDispatch();

    return (
        <div className="d-flex align-items-center" style={{ gap: "8px" }}>
            {showAddSurveyButton && user?.permissions?.SURVEY_EDIT === 1 && (
                <NavLink to="/survey/add" className="cardToolbarMuted">
                    <ButtonWithText
                        variant="successInverted"
                        leftIcon="plus"
                        // onClick={() => dispatch(actions.toggleAccountAddButton(true))}
                    >
                        Create New Evaluation Form
                    </ButtonWithText>
                </NavLink>
            )}
            {surveyId && showEditSurveyButton && statusEditSurveyButton && user?.permissions?.SURVEY_EDIT === 1 && (
                <NavLink to={"/survey/edit/" + surveyId} className="cardToolbarMuted">
                    <ButtonWithText leftIcon="edit" variant="successInverted">
                        Edit
                    </ButtonWithText>
                </NavLink>
            )}
        </div>
    );
};

export default SurveyEvaluationFormsActions;
