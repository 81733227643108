/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import * as campaignsRedux from "../../../app/popleads/modules/campaigns/redux/campaignsRedux";
import ButtonWithText from "app/popleads/components/ui-kit/Button";

function DropdownPopleads_precampaign(props) {
    const selectedLength = props.selectedLength;
    const permissions = props.user && props.user.permissions ? props.user.permissions : null;

    return (
        <>
            {/*begin::Navigation*/}
            <ul className="navi navi-hover p-1">
                {permissions.LAUNCH_CLONE_PRECAMPAIGNS === 1 && (
                    <li className="navi-item mx-3 mt-5">
                        <span>
                            <div tabIndex="0">
                                <ButtonWithText
                                    variant="successInverted"
                                    leftIcon="send"
                                    disabled={selectedLength < 1}
                                    className={"mb-5 w-100"}
                                    onClick={() => {
                                        props.setStatusLaunchModal(true);
                                    }}
                                >
                                    Launch
                                </ButtonWithText>
                                {/* <img src='/media/shuttle.png' />
                                    Launch
                                </Button> */}
                            </div>
                        </span>
                    </li>
                )}
                {permissions.LAUNCH_CLONE_PRECAMPAIGNS === 1 && (
                    <li className="navi-item mx-3">
                        <span>
                            <div tabIndex="0">
                                <ButtonWithText
                                    variant="neutralInverted"
                                    leftIcon="copy"
                                    disabled={selectedLength < 1}
                                    className={"mb-5 w-100"}
                                    onClick={() => {
                                        props.setStatusCloneModal(true);
                                    }}
                                >
                                    Clone
                                </ButtonWithText>
                                {/* <span className="svg-icon svg-icon-x svg-icon-lightgrey mr-5">
                                        <SVG title="Clone" src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")} />
                                    </span>
                                    Clone
                                </Button> */}
                            </div>
                        </span>
                    </li>
                )}

                {permissions.DELETE_PRECAMPAIGN === 1 && (
                    <li className="navi-item mx-3">
                        <span>
                            <div tabIndex="0">
                                <ButtonWithText
                                    variant="dangerInverted"
                                    leftIcon="trash"
                                    disabled={selectedLength < 1}
                                    className={"mb-5 w-100"}
                                    onClick={() => {
                                        props.setStatusDeleteModal(true);
                                    }}
                                >
                                    Delete
                                </ButtonWithText>
                                {/* <span className="svg-icon svg-icon-x svg-icon-white mr-5">
                                        <SVG title="Delete" src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                    </span>
                                    Delete
                                </Button> */}
                            </div>
                        </span>
                    </li>
                )}

                {/*(permissions.INSERT_EDIT_PRECAMPAIGNS === 1 || permissions.PRECAMPAIGN_TRAINER_EDIT === 1) &&
                <li className="navi-item">
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-edit`}>
                                <strong>Edit</strong>
                            </Tooltip>
                        }
                    >
                        <span>
                            <div tabIndex="0" >
                                {!props.editInTable ?
                                    <button type="button" className="btn btn-primary btn-md btn-block editButton" onClick={() => {
                                        props.setEditInTable(true);
                                    }}>
                                        <span className="svg-icon svg-icon-x svg-icon-lightgrey mr-5">
                                            <SVG title="Edit" src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                                        </span>
                                        Edit
                                    </button> :
                                    <button type="button" className="btn btn-primary btn-md btn-block editButton" onClick={() => {
                                        props.setEditInTable(false);
                                    }}>
                                        Complete Edit
                                    </button>
                                }
                            </div>
                        </span>
                    </OverlayTrigger>

                </li>
            */}
            </ul>
            {/*end::Navigation*/}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedLength: state.campaigns.selectedLength,
        editInTable: state.campaigns.editInTable,
        user: state.auth.user,
    };
};

const mapDispatchToProps = {
    ...campaignsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownPopleads_precampaign);
