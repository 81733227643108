import ButtonWithText from "app/popleads/components/ui-kit/Button";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as travelRedux from "../../modules/travels/redux/travelRedux";
import BusinessTripsFilter from "app/popleads/modules/filters/BusinessTripsFilter";

const BusinessTripActions = (props) => {
    return (
        <div className="d-flex align-items-center w-100" style={{ gap: "8px" }}>
            {props.travelGridButton && (
                <Link to="/travel/calendar" style={{ padding: 0 }}>
                    <ButtonWithText variant="checkboxActive" icon="grid" />
                </Link>
            )}
            {props.travelDateListButton && (
                <Link to="/travel/date-list" style={{ padding: 0 }}>
                    <ButtonWithText variant="checkboxActive" icon="calendar" />
                </Link>
            )}
            {props.travelNewValidVisaForEmployee && (
                <ButtonWithText
                    variant="successInverted"
                    leftIcon="plus"
                    onClick={() => {
                        props.setTravelVisaModalOpen(true);
                    }}
                >
                    Add New Visa
                </ButtonWithText>
            )}

            {props.addNewTravelBtn && (
                <div className=" d-md-flex ml-2 ml-md-0">
                    <ButtonWithText
                        className="mr-2"
                        variant="successInverted"
                        leftIcon="plus"
                        onClick={() => {
                            props.setTravelVisaRequestModal(true);
                        }}
                    >
                        New Visa Request
                    </ButtonWithText>
                    <Link to="/travel/add-new-trips">
                        <ButtonWithText variant="successInverted" leftIcon="plus">
                            New Trip Request
                        </ButtonWithText>
                    </Link>
                </div>
            )}

            {!props.addNewTravelBtn && props.travelFilter && <BusinessTripsFilter />}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        travelDateListButton: state.travel.travelDateListButton,
        travelNewValidVisaForEmployee: state.travel.travelNewValidVisaForEmployee,
        travelFilter: state.travel.travelFilter,
        addNewTravelBtn: state.travel.addNewTravelBtn,
        travelGridButton: state.travel.travelGridButton,
    };
};

const mapDispatchToProps = {
    ...travelRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTripActions);
