import React from "react";
import { Dropdown } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import styles from "../../../popleads/assets/css/design_3.0/CustomDatePicker.module.scss";
import { customDateRanges, getDateObject } from "app/popleads/app/functions/util";
import { CustomToggleIcon } from "app/popleads/modules/activities/utils";
import ButtonWithText from "app/popleads/components/ui-kit/Button";

const DateDropdown = ({ selectedDate, setSelectedDate }) => {
    const newSelectedDate = selectedDate.key ? selectedDate : { ...selectedDate, key: "selection" };
    return (
        <Dropdown id="datePickerDropdown" className="d-none d-xxl-block" align="end">
            <Dropdown.Toggle as={CustomToggleIcon} id="dropdown-custom-components">
                <ButtonWithText variant="checkboxActive" leftIcon="check">
                    {selectedDate?.dateString}
                </ButtonWithText>
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="dropdown-menu dropdown-menu-xl px-4 py-6 dropdown-menu-anim-up"
                style={{ width: "max-content" }}
            >
                <DateRangePicker
                    className={styles.rdrDateRangePickerWrapper}
                    ranges={[newSelectedDate]}
                    staticRanges={customDateRanges(new Date(null), new Date())}
                    inputRanges={[]}
                    direction="vertical"
                    onChange={(item) => {
                        const date = getDateObject(item.selection);
                        setSelectedDate(date);
                    }}
                />
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default DateDropdown;
