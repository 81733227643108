import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DateDropdown from "../components/DateDropdown";
import { actions } from "app/popleads/modules/accounts/redux/accountsRedux";
import AccountFilterDrawer from "../drawers/AccountFilterDrawer";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import ChatGptDrawer from "app/popleads/components/Chat/ChatGptDrawer";

const AccountDetailActions = () => {
    const { selectedDate, isOpenAccountEditButton } = useSelector((state) => state.accounts);
    const dispatch = useDispatch();

    return (
        <div className="d-flex align-items-center" style={{ gap: "8px" }}>
            {isOpenAccountEditButton ? (
                <ButtonWithText
                    variant="successInverted"
                    onClick={() => dispatch(actions.toggleAccountEditButton(false))}
                >
                    Done
                </ButtonWithText>
            ) : (
                <ButtonWithText
                    leftIcon="edit"
                    variant="successInverted"
                    onClick={() => dispatch(actions.toggleAccountEditButton(true))}
                >
                    Edit
                </ButtonWithText>
            )}
            <ButtonWithText
                leftIcon="minimize-2"
                variant="neutralInverted"
                onClick={() => dispatch(actions.toggleAccountMergeButton(true))}
            >
                Merge
            </ButtonWithText>
            <DateDropdown
                selectedDate={selectedDate}
                setSelectedDate={(date) => dispatch(actions.setSelectedDate(date))}
            />
            <AccountFilterDrawer isForAccountDetail />
            <div
                style={{
                    width: "40px",
                    height: "40px",
                    overflow: "hidden",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "40px",
                    minHeight: "40px",
                }}
            >
                <ChatGptDrawer className={"m-0 btn-borderless"} />
            </div>
        </div>
    );
};

export default AccountDetailActions;
