/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { forwardRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownPopleads_precampaign from "../../../../_metronic/_partials/dropdowns/DropdownPopleads_precampaign";
import { connect } from "react-redux";
import * as campaignsRedux from "../../modules/campaigns/redux/campaignsRedux";
import ButtonWithText from "app/popleads/components/ui-kit/Button";

const QuickActionsDropdownToggle = forwardRef((props, ref) => {
    return (
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="quick-actions-tooltip">Quick actions</Tooltip>}>
            <a
                ref={ref}
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    props.onClick(e);
                }}
                id="kt_subheader_quick_actions"
            >
                <ButtonWithText variant="successActive" icon="file-plus" />
            </a>
        </OverlayTrigger>
    );
});

function PrecampaignsActionDropdown(props) {
    return (
        <>
            {props.user &&
                props.user.permissions &&
                props.selectedLength > 0 &&
                (props.user?.permissions?.LAUNCH_CLONE_PRECAMPAIGNS === 1 ||
                    props.user?.permissions?.DELETE_PRECAMPAIGN === 1) && (
                    <Dropdown className="dropdown-inline" drop="down" alignRight>
                        <Dropdown.Toggle as={QuickActionsDropdownToggle} id="dropdown-toggle-quick-actions-subheader" />

                        <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-md dropdown-menu-right dropdownDropdownPreCampaigns">
                            <DropdownPopleads_precampaign />
                        </Dropdown.Menu>
                    </Dropdown>
                )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        selectedLength: state.campaigns.selectedLength,
    };
};

const mapDispatchToProps = {
    ...campaignsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrecampaignsActionDropdown);
