import ButtonWithText from "app/popleads/components/ui-kit/Button";
import SalesFilters from "app/popleads/modules/filters/SalesFilters";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import * as reportsRedux from "../../modules/reports/redux/reportsRedux";
import DepartmentFilter from "app/popleads/modules/filters/components/DepartmentFilter";
import EmployeeFilter from "app/popleads/modules/filters/components/EmployeeFilter";
import DateDropdown from "../components/DateDropdown";
import InquiryTypeFilter from "app/popleads/modules/filters/components/InquiryTypeFilter";
import FranchiseFilter from "app/popleads/modules/filters/components/FranchiseFilter";
import { CustomToggleIcon } from "app/popleads/modules/activities/utils";

const ReportsPageActions = (props) => {
    const employee =
        props.selectedEmployee?.length > 0
            ? props.selectedEmployee?.length === 1
                ? props.selectedEmployee[0].label
                : `${props.selectedEmployee.length} Employees`
            : "All Employees";
    const department =
        props.selectedDepartment?.length > 0
            ? props.selectedDepartment?.length === 1
                ? props.selectedDepartment[0].label
                : `${props.selectedDepartment.length} Units`
            : "All Units";
    const inquiry =
        props.selectedInquiryType?.length > 0
            ? props.selectedInquiryType?.length === 1
                ? props.selectedInquiryType[0].label
                : `${props.selectedInquiryType.length} Types`
            : "All Types";
    const franchise =
        props.selectedFranchise?.length > 0
            ? props.selectedFranchise?.length === 1
                ? props.selectedFranchise[0].label
                : `${props.selectedFranchise.length} Franchises`
            : "HQ";

    return (
        <div className="d-flex align-items-center" style={{ gap: "8px" }}>
            {/* Employees Dropdown */}
            {props.employeeFilter && (
                <Dropdown id="employeeDropdown" className="d-none d-lg-block">
                    <Dropdown.Toggle as={CustomToggleIcon} id="kt_employee_toggle">
                        <ButtonWithText
                            variant={employee === "All Employees" ? "checkboxInActive" : "checkboxActive"}
                            leftIcon={employee !== "All Employees" && "check"}
                        >
                            {employee}
                        </ButtonWithText>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl">
                        <EmployeeFilter isMultiSelect={true} isLoading={true} />
                    </Dropdown.Menu>
                </Dropdown>
            )}
            {/* Units Dropdown */}
            {props.departmentFilter && (
                <Dropdown id="employeeDropdown" className="d-none d-lg-block" align="end">
                    <Dropdown.Toggle as={CustomToggleIcon} id="kt_employee_toggle">
                        <ButtonWithText
                            variant={department === "All Units" ? "checkboxInActive" : "checkboxActive"}
                            leftIcon={department !== "All Units" && "check"}
                        >
                            {department}
                        </ButtonWithText>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl">
                        <DepartmentFilter isMultiSelect={true} isLoading={true} />
                    </Dropdown.Menu>
                </Dropdown>
            )}
            {/* Dates Dropdown */}
            {(props.dateFilter || props.dateFilterSurvey) && (
                <DateDropdown selectedDate={props.selectedDate} setSelectedDate={(date) => props.selectDate(date)} />
            )}
            {/* Types Dropdown */}
            {props.inquiryTypeFilter && (
                <Dropdown id="inquiryTypeDropdown" className="d-none d-lg-block" align="end">
                    <Dropdown.Toggle as={CustomToggleIcon} id="kt_inquiry_type_toggle">
                        <ButtonWithText
                            variant={inquiry === "All Types" ? "checkboxInActive" : "checkboxActive"}
                            leftIcon={inquiry !== "All Types" && "check"}
                        >
                            {inquiry}
                        </ButtonWithText>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl">
                        <InquiryTypeFilter isMultiSelect={true} />
                    </Dropdown.Menu>
                </Dropdown>
            )}
            {/* Franchise Dropdown */}
            {props.franchiseFilter && props.user.company.headquartersId === null && (
                <Dropdown id="inquiryTypeDropdown" className="d-none d-lg-block" align="end">
                    <Dropdown.Toggle as={CustomToggleIcon} id="kt_employee_toggle">
                        <ButtonWithText
                            variant={franchise === "HQ" ? "checkboxInActive" : "checkboxActive"}
                            leftIcon={franchise !== "HQ" && "check"}
                        >
                            {franchise}
                        </ButtonWithText>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl">
                        <FranchiseFilter isMultiSelect={true} />
                    </Dropdown.Menu>
                </Dropdown>
            )}

            {props.salesFilterActions && <SalesFilters />}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        salesFilterActions: state.reports.salesFilterActions,
        selectedDepartment: state.reports.selectedDepartment,
        selectedEmployee: state.reports.selectedEmployee,
        selectedDate: state.reports.selectedDate,
        selectedInquiryType: state.reports.selectedInquiryType,
        selectedFranchise: state.reports.selectedFranchise,
        departmentFilter: state.app.departmentFilter,
        employeeFilter: state.app.employeeFilter,
        dateFilter: state.app.dateFilter,
        dateFilterSurvey: state.app.dateFilterSurvey,
        inquiryTypeFilter: state.app.inquiryTypeFilter,
        franchiseFilter: state.app.franchiseFilter,
        user: state.auth.user,
    };
};

const mapDispatchToProps = {
    ...reportsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPageActions);
