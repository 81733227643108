import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/popleads/modules/deals/redux/dealsRedux";
import DealsFilters from "app/popleads/modules/filters/DealsFilters";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import SVG from "react-inlinesvg";

const DealsActions = () => {
    const { user } = useSelector((state) => state.auth);
    const { dealExcelExport } = useSelector((state) => state.deals);

    const dispatch = useDispatch();

    return (
        <div className="d-flex align-items-center" style={{ gap: "8px" }}>
            <DealsFilters />
            {dealExcelExport && user.permissions && user.permissions.EXPORT_DEALS === 1 && (
                <ButtonWithText variant="successActive" onClick={() => dispatch(actions.setDealExcelFile(true))}>
                    <SVG src="/media/svg/popleads/export_excel_icon.svg"></SVG>
                </ButtonWithText>
            )}
        </div>
    );
};

export default DealsActions;
