import React from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

import IndustryFilter from "app/popleads/modules/filters/components/IndustryFilter";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import { CustomToggleIcon } from "app/popleads/modules/activities/utils";

const IndustryDropdown = ({ module }) => {
    const { selectedIndustry } = useSelector((state) => state[module]);
    const industry = selectedIndustry?.label ?? "All Industries";

    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggleIcon} id="dropdown-custom-components">
                <ButtonWithText
                    variant={industry === "All Industries" ? "checkboxInActive" : "checkboxActive"}
                    leftIcon={industry !== "All Industries" && "check"}
                >
                    {industry}
                </ButtonWithText>
            </Dropdown.Toggle>
            <Dropdown.Menu className="my-2 p-0 dropdown-menu" style={{ width: "400px", background: "transparent" }}>
                <IndustryFilter />
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default IndustryDropdown;
