import React from "react";
import styled from "styled-components";
import { colors } from "./colors";
import { Body2, Heading5, Heading6 } from "./Text";
import { toAbsoluteUrl } from "_metronic/_helpers";
import SVG from "react-inlinesvg";

//todo: 0.875 rem  yerine 28. satırda başka bir font-size kullanıldı
//todo: 1 rem olmadığı için heading5 kullanıldı
const CardContainer = styled.div`
    background-color: #fff;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid ${colors.neutral[100]};

    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
`;
const StyledCardContent = styled(Heading6)`
    color: ${colors.neutral[800]};
    letter-spacing: -0.17px;
`;
const StyledTitle = styled(Body2)`
    color: ${colors.neutral[800]};
`;
const StyledContentWrapper = styled.div`
    border-bottom: ${({ isLast }) => (isLast ? "none" : `1px solid ${colors.neutral[100]}`)};
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ExtensionCard = ({ cardTitle, cardItems, button }) => {
    return (
        <div>
            <div className="mb-3 d-flex align-items-center justify-content-between">
                <Heading5 className="d-block ">{cardTitle}</Heading5>
                {button && <div>{button}</div>}
            </div>
            <CardContainer>
                {cardItems.map((cardItem, index) => (
                    <div key={index} className="d-flex align-items-center">
                        <div className="mr-4">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/course-execution.svg")} />
                        </div>
                        <StyledContentWrapper isLast={index === cardItems.length - 1}>
                            {cardItem.content && <StyledCardContent>{cardItem.content}</StyledCardContent>}
                            {cardItem.footer && <StyledTitle className="mt-1">{cardItem.footer}</StyledTitle>}
                        </StyledContentWrapper>
                    </div>
                ))}
            </CardContainer>
        </div>
    );
};
export default ExtensionCard;
