import React from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

import TypeFilter from "app/popleads/modules/filters/components/TypeFilter";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import { CustomToggleIcon } from "app/popleads/modules/activities/utils";

const TypeDropdown = ({ module }) => {
    const { selectedType } = useSelector((state) => state[module]);
    const type = selectedType?.label ?? "All Types";

    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggleIcon} id="dropdown-custom-components">
                <ButtonWithText
                    variant={type === "All Types" ? "checkboxInActive" : "checkboxActive"}
                    leftIcon={type !== "All Types" && "check"}
                >
                    {type}
                </ButtonWithText>
            </Dropdown.Toggle>
            <Dropdown.Menu className="my-2 p-0 dropdown-menu" style={{ width: "400px", background: "transparent" }}>
                <TypeFilter />
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default TypeDropdown;
