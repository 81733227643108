import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import IndustryDropdown from "../components/IndustryDropdown";
import TypeDropdown from "../components/TypeDropdown";
import DateDropdown from "../components/DateDropdown";
import AccountFilterDrawer from "../drawers/AccountFilterDrawer";
// import SearchFilter from "app/popleads/modules/filters/components/SearchFilter";
import { actions } from "app/popleads/modules/accounts/redux/accountsRedux";
import Toggle from "app/popleads/components/ui-kit/Toggle";
import ChatGptDrawer from "app/popleads/components/Chat/ChatGptDrawer";
import InputWithIcon from "app/popleads/components/ui-kit/Input";
import * as accounts from "../../modules/accounts/redux/accountsRedux";

const AccountListActions = (props) => {
    const { gridOrTable, selectedDate } = useSelector((state) => state.accounts);

    const handleSearch = (value) => {
        props.search(value);
    };

    const handleClearInput = () => {
        handleSearch("");
    };

    const options = [
        {
            value: "grid",
            activeVariant: "primary",
            icon: "grid",
            tooltip: "Grid View",
        },
        {
            value: "table",
            activeVariant: "primary",
            icon: "list",
            tooltip: "Table View",
        },
    ];

    const dispatch = useDispatch();
    return (
        <div className="d-flex align-items-center w-100" style={{ gap: "8px" }}>
            {/* <SearchFilter module="account" className="searchPL" /> */}
            <InputWithIcon
                leftIcon="search"
                rightIcon={props.searchInput?.length > 0 ? "x" : null}
                minLength={1}
                debounceTimeout={300}
                onChange={(event) => handleSearch(event.target.value)}
                leftIconClick={handleClearInput}
                rightIconClick={handleClearInput}
                className="w-100 form-control"
                placeholder={"Search..."}
                id="kt_datatable_search_query"
                value={props.searchInput}
            />
            <div className="d-none d-md-block">
                <IndustryDropdown module="accounts" />
            </div>
            <div className="d-none d-md-block">
                <TypeDropdown module="accounts" />
            </div>
            <DateDropdown
                selectedDate={selectedDate}
                setSelectedDate={(date) => dispatch(actions.setSelectedDate(date))}
            />
            <AccountFilterDrawer />
            <div className="d-none d-md-block">
                <Toggle
                    selected={gridOrTable}
                    setSelected={(viewType) => dispatch(actions.setGridOrTable(viewType))}
                    options={options}
                />
            </div>
            <div
                style={{
                    width: "40px",
                    height: "40px",
                    overflow: "hidden",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "40px",
                    minHeight: "40px",
                }}
            >
                <ChatGptDrawer className={"m-0 btn-borderless"} />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        searchInput: state.accounts.searchInput,
    };
};

const mapDispatchToProps = {
    ...accounts.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountListActions);
