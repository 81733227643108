import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PrecampaignsFilterDrawer from "../drawers/PrecampaignsFilterDrawer";
import { actions } from "app/popleads/modules/campaigns/redux/campaignsRedux";
import PrecampaignsActionDropdown from "../components/PrecampaignsActionDropdown";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import InputWithIcon from "app/popleads/components/ui-kit/Input";
import Toggle from "app/popleads/components/ui-kit/Toggle";
import ChatGptDrawer from "app/popleads/components/Chat/ChatGptDrawer";

const PrecampaignsActions = () => {
    const { user } = useSelector((state) => state.auth);
    const { searchInput_precampaign, gridOrTable } = useSelector((state) => state.campaigns);

    const dispatch = useDispatch();

    const options = [
        {
            value: "grid",
            activeVariant: "primary",
            icon: "grid",
            tooltip: "Grid View",
        },
        {
            value: "table",
            activeVariant: "primary",
            icon: "list",
            tooltip: "Table View",
        },
    ];

    const handleSearch = (value) => {
        dispatch(actions.searchPrecampaign(value));
    };

    const handleClearInput = () => {
        handleSearch("");
    };

    return (
        <div
            className="d-flex flex-column flex-md-row align-items-end align-items-md-center w-100"
            style={{ gap: "8px" }}
        >
            <div className="w-100">
                <InputWithIcon
                    leftIcon="search"
                    rightIcon={searchInput_precampaign?.length > 0 ? "x" : null}
                    minLength={1}
                    debounceTimeout={300}
                    onChange={(event) => handleSearch(event.target.value)}
                    leftIconClick={handleClearInput}
                    rightIconClick={handleClearInput}
                    className="w-100 form-control"
                    placeholder={"Search..."}
                    id="kt_datatable_search_query"
                    value={searchInput_precampaign}
                />
            </div>
            <div className="d-flex align-items-center" style={{ gap: "8px" }}>
                {user?.permissions?.INSERT_EDIT_PRECAMPAIGNS === 1 && (
                    <NavLink to="/campaigns/precampaigns/add" className="cardToolbarMuted">
                        <ButtonWithText variant="successInverted" leftIcon="plus" className="d-none d-md-block">
                            New Pre-Campaign
                        </ButtonWithText>
                        <ButtonWithText variant="successInverted" leftIcon="plus" className="d-block d-md-none">
                            Add
                        </ButtonWithText>
                    </NavLink>
                )}
                <PrecampaignsFilterDrawer />
                <Toggle
                    selected={gridOrTable}
                    setSelected={(viewType) => dispatch(actions.gridOrTableButton(viewType))}
                    options={options}
                />
                <PrecampaignsActionDropdown />
                <div
                    style={{
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                        borderRadius: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: "40px",
                        minHeight: "40px",
                    }}
                >
                    <ChatGptDrawer className={"m-0 btn-borderless"} />
                </div>
            </div>
        </div>
    );
};

export default PrecampaignsActions;
