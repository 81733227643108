/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import * as util from "../app/popleads/app/functions/util";
// import { RepeatRounded } from "@material-ui/icons";
import EvaluationFormReport from "./popleads/modules/pdf/EvaluationFormReport";
import FinalReport from "./popleads/modules/pdf/FinalReport/FinalReport";
import PlaygroundHome from "./popleads/modules/playground/PlaygroundHome";

let count = 0;
export function Routes() {
    const [isUserLoaded, setUserLoaded] = useState(0);

    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user.name !== "",
        }),
        shallowEqual
    );
    //PopLeads change
    let authToken = util.getCookieValue(util.getCookieName());
    if (!authToken) {
        authToken = util.getUrlQueryParam("sid");
        if (authToken) util.createCookie(util.getCookieName(), authToken, 14);
    }

    if (authToken && count == 0) {
        setUserLoaded(true);
        count++;
    }

    if (!authToken && count == 0) {
        setUserLoaded(true);
        count++;
    }

    const pathname = window.location.pathname;

    if (!(isAuthorized || authToken)) {
        if (
            pathname !== "/" &&
            pathname !== "/logout" &&
            !pathname.startsWith("/auth") &&
            !pathname.startsWith("/error")
        ) {
            localStorage.setItem("prevUrl", window.location.pathname + window.location.search);
        } else {
            localStorage.removeItem("prevUrl");
        }
    }

    //if(authToken) setUserLoaded(true);
    if (!isUserLoaded) {
        return <div></div>;
    }

    return (
        <Switch>
            {!(isAuthorized || authToken) ? ( //PopLeads change
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/" />
            )}

            <Route path="/error" component={ErrorsPage} />
            <Route path="/playground" component={PlaygroundHome} />
            <Route path="/logout" component={Logout} />

            <Route path="/pdf/evaluation-report" component={EvaluationFormReport} />
            <Route path="/pdf/final-report" component={FinalReport} />

            {!(isAuthorized || authToken) ? ( //PopLeads change
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login" />
            ) : (
                <Layout>
                    <BasePage />
                </Layout>
            )}
        </Switch>
    );
}
