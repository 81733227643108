import React, { useEffect, useRef, useState } from "react";
import Button from "../Button";
import ChatItem from "./ChatItem";
import ChatSkeleton from "./ChatSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/popleads/app/redux/appRedux";
import { TooltipOverlay } from "../TooltipOverlay";
import moment from "moment";
import useDisclosure from "app/popleads/hooks/useDisclosure";
import { post } from "app/popleads/networking/RequestService";
import { silentlyParseJSON } from "app/popleads/app/functions/util";
import { toast } from "react-toastify";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { Button as BootstrapButton } from "react-bootstrap";
import CustomDrawer from "../drawer/CustomDrawer";
import { DrawerBody, DrawerContent, DrawerFooter, DrawerHeader } from "../drawer/CustomDrawer";

import styles from "./ChatGptDrawer.module.scss";

let threadId = undefined;

const ChatGptDrawer = ({ className, style }) => {
    const [messageInput, setMessageInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { isOpen, onClose, onToggle } = useDisclosure();
    const { user } = useSelector((state) => state.auth);
    const { chatMessages } = useSelector((state) => state.app);
    const dispatch = useDispatch();

    const messagesEndRef = useRef(null);

    const sendMessage = () => {
        setIsLoading(true);
        const updatedMessages = [
            ...chatMessages,
            {
                id: moment().valueOf(),
                role: "user",
                content: messageInput,
                timestamp: moment().valueOf(),
            },
        ];
        dispatch(actions.setChatMessages(updatedMessages));

        const requestObj = {
            fn: "chatGptAskQuestion",
            question: messageInput,
            threadId,
        };
        post(requestObj)
            .then((res) => {
                setMessageInput("");
                if (!threadId) threadId = res.data.threadId;
                const messages = silentlyParseJSON(res.data.messages, "[]");
                const lastMessage = messages.filter((message) => message.role === "assistant")?.[0];
                if (lastMessage) {
                    const assistantResponse = {
                        id: lastMessage.id,
                        role: lastMessage.role,
                        content: lastMessage.content?.[0]?.text?.value ?? "",
                        timestamp: moment().valueOf(),
                    };
                    dispatch(actions.setChatMessages([...updatedMessages, assistantResponse]));
                }
            })
            .catch(() => {
                toast("Something went wrong with ChatGPT, please try again later...", { type: "error" });
                dispatch(actions.setChatMessages(updatedMessages.slice(0, -1)));
            })
            .finally(() => setIsLoading(false));
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            if (messageInput.length >= 3 && !isLoading) {
                sendMessage();
            }
        }
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        dispatch(
            actions.setChatMessages([
                {
                    id: moment().valueOf(),
                    role: "assistant",
                    content: `Hi ${user.name}, I'm Sarah, your HR Specialist. How can I help you?`,
                    timestamp: moment().valueOf(),
                },
            ])
        );
    }, [dispatch, user]);

    useEffect(() => {
        isLoading && scrollToBottom();
    }, [isLoading]);

    return (
        <>
            <Button
                icon
                outline
                className={className ? className : "ml-1 ml-md-4 btn-borderless"}
                src="/media/svg/popleads/hr_assistant_sarah.svg"
                style={style}
                onClick={onToggle}
            />
            <CustomDrawer position="right" size="lg" open={isOpen} onClose={onClose}>
                <DrawerContent>
                    <DrawerHeader onClose={onClose}>HR Specialist</DrawerHeader>
                    <DrawerBody>
                        {chatMessages.length > 0 && (
                            <div className={styles.messages}>
                                {chatMessages.map((message) => (
                                    <ChatItem
                                        key={message.id}
                                        role={message.role}
                                        content={message.content}
                                        timestamp={message.timestamp}
                                    />
                                ))}
                            </div>
                        )}
                        {isLoading && <ChatSkeleton />}
                        <div ref={messagesEndRef} />
                    </DrawerBody>
                    <DrawerFooter>
                        <div className={styles.drawerFooter}>
                            <textarea
                                className={styles.chatInput}
                                value={messageInput}
                                rows={2}
                                onChange={(e) => setMessageInput(e.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="Message HR Specialist..."
                                disabled={isLoading}
                            />
                            <TooltipOverlay placement="top" tooltip="Send Message">
                                <BootstrapButton
                                    className="customBootstrapBtn"
                                    variant="success"
                                    onClick={sendMessage}
                                    disabled={messageInput.length < 3 || isLoading}
                                >
                                    <SVG src={toAbsoluteUrl("/media/svg/popleads/chevron-up.svg")} />
                                </BootstrapButton>
                            </TooltipOverlay>
                        </div>
                    </DrawerFooter>
                </DrawerContent>
            </CustomDrawer>
        </>
    );
};

export default ChatGptDrawer;
