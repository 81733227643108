export const actionTypes = {
    ToggleAddPreCampaignButton: "[Action] Toggle Add Pre-Campaign Button",
    ToggleCoursesActionButtons: "[Action] Toggle Add Course Button",
    GridOrTableButton: "[Action] Toggle Grid or Table",
    GridOrTableFilter: "[Action] Filter Grid or Table",
    Search: "[Action] Search",
    SelectAllButton: "[Action] Select All Button",
    IsSelectedAll: "[Action] Is Selected All",
    QuickActionsButton: "[Action] Quick Actions Button",
    EditMoreInfoButton: "[Action] Edit More Info Button",
    IsOpenEditMoreInfoButton: "[Action] Toggle More Info Button",
    StatusEditMoreInfoButton: "[Action] Status More Info Button",
    DuplicateButton: "[Action] Duplicate Button",
    StatusDuplicateButton: "[Action] Status Duplicate Button",
    StatusCloneModal: "[Action] Status Clone Modal",
    StatusDeleteModal: "[Action] Status Delete Modal",
    StatusLaunchModal: "[Action] Status Launch Modal",
    SelectedLength: "[Action] Selected Length",
    SearchFilter: "[Action] Search Filter",
    ShowSearchFilter: "[Action] Set Search Filter",
    PreCampaignList: "[Action] PreCampaign List",
    PreCampaignListCountTotal: "[Action] PreCampaign List Count Total",
    PreCampaignListPageNumber: "[Action] PreCampaign List Page Number",
    PreCampaignListSelectedList: "[Action] PreCampaign List Selected List",
    EditInTable: "[Action] PreCampaign Edit In Table",
    IsCourseEditButtonExist: "[Action] Is Course Edit Button",
    ToggleCourseEditButton: "[Action] Toggle Course Edit Button",
    SetSubheader: "[Action] Set Subheader",
    SetLanguage: "[Action] Language",
    SetCourseSlug: "[Action] Course Slug",
    SetSelectedCampaign: "[Action] Set selected Campaign",
    SetCourseStatus: "[Action] Course Status",
    SetCourseDetailLoading: "[Action] Course Detail Loading",
    CoursesFilter: "[Action] Courses Filter",
    CoursesStatusFilter: "[Action] Courses Status Filter",
    CourseBusinessSectorFilter: "[Action] Courses Business Sector Filter",
    CourseMasterTrainer: "[Action] Course Master Trainer",
    CourseDesignationFilter: "[Action] Course Designation Filter",
    CourseLanguageFilter: "[Action] Course Language Filter",
    CourseLevelTypeFilter: "[Action] Course Level Type Filter",
    CourseTypeFilter: "[Action] Course Type Filter",
    CourseAcademy: "[Action] Course Academy",
    CourseProjectManager: "[Action] Course Project Manager",
    CourseRevenueFilter: "[Action] Course Revenue Filter",
    CourseEvaluationScore: "[Action] Course Evaluation Score",
    CourseNumberOfDelegation: "[Action] Course Number of Delegation",
    CourseLVTPrice: "[Action] Course Lvt Price",
    CourseF2FPrice: "[Action] Course F2F Price",
    CourseSubsidized: "[Action] Course Subsidized",
    CourseRefund: "[Action] Course Refund Type",
    CourseExcelExport: "[Action] Course Excel Download",
    CourseDetailExcel: "[Action] Course Detail Excel Download",
    ShowPrecampaignsActions: "[Action] Show Precampaigns Action Buttons",
    ShowCourseDetailActions: "[Action] Show Course Detail Action Buttons",
    ViewType: "[Action] View Type",
    IsAttendanceDrawerOpen: "[Action] Is Activity Drawer Open",
    SelectedAttendanceFilter: "[Action] Campaign Filter",
    AttendanceListForFilter: "[Action] Campaign List For Filter",
    selectedAttendanceListFromFilter: "[Action] Selected Campaign List From Filter",
    IsAttendanceLoading: "[Action] Activities Loading",
    IsFilterByAttendanceModalShown: "[Action] Is Filter By Campaign Modal Shown",
    AttendanceDetailModalId: "[Action] Campaign Detail Modal Id",
    ShowAttendanceSidebar: "[Action] Show Campaign Sidebar",
    IsCampaignOver: "[Action] Is Campaign Over",
};

const initialReportsState = {
    coursesFilter: false,
    isPreCampaignButton: false,
    isCoursesActionButtons: false,
    gridOrTable: "table",
    gridOrTableFilter: false,
    searchInput_precampaign: "",
    selectAllButton: false,
    isSelectedAll: false,
    quickActionsButton: false,
    editMoreInfoButton: false,
    isOpenEditMoreInfoButton: false,
    statusEditMoreInfoButton: undefined,
    duplicateButton: false,
    statusDuplicateButton: undefined,
    statusCloneModal: undefined,
    statusDeleteModal: undefined,
    statusLaunchModal: undefined,
    selectedLength: 0,
    searchFilter: undefined,
    showSearchFilter: undefined,
    preCampaignList: undefined,
    preCampaignListCountTotal: 0,
    preCampaignListPageNumber: 0,
    preCampaignListSelectedList: undefined,
    editInTable: false,
    isCourseEditButton: false,
    isOpenCourseEditButton: false,
    subheader: undefined,
    language: { value: "en", label: "English" },
    courseSlug: "",
    selectedCampaign: null,
    courseStatus: undefined,
    isCourseDetailLoading: false,
    coursesStatusFilter: undefined,
    courseBusinessSectorFilter: [],
    courseMasterTrainerFilter: [],
    courseDesignationFilter: [],
    courseLanguageFilter: [],
    courseLevelTypeFilter: [],
    courseTypeFilter: [],
    courseAcademyFilter: [],
    courseProjectManagerFilter: [],
    courseRevenueFilter: {
        min: "",
        max: "",
    },
    courseEvaluationScore: {
        min: "",
        max: "",
    },
    courseNumberOfDelegation: {
        min: "",
        max: "",
    },
    courseLVTPrice: {
        min: "",
        max: "",
    },
    courseF2Fprice: {
        min: "",
        max: "",
    },
    subsidizedByHrdf: undefined,
    refundType: undefined,
    coursesDetailExcel: false,
    courseExcelExport: false,
    showPrecampaignsActions: false,
    ShowCourseDetailActions: false,
    viewType: null,
    isAttendanceDrawerOpen: false,
    selectedAttendanceFilter: {
        status: [],
        campaignType: [{ label: "In-house", value: "In-house" }],
        year: [],
        month: [],
        week: [],
        campaignAccount: [],
        campaignPm: [],
        campaignAssistant: [],
        campaignCountry: [],
    },
    attendanceListForFilter: [],
    selectedAttendanceListFromFilter: [],
    isAttendanceLoading: false,
    isFilterByAttendanceModalShown: false,
    attendanceDetailModalId: "",
    showAttendanceSidebar: true,
    isCampaignOver: false,
};

export const reducer = (state = initialReportsState, action) => {
    switch (action.type) {
        case actionTypes.CoursesFilter: {
            const { coursesFilter } = action.payload;
            return { ...state, coursesFilter };
        }
        case actionTypes.ToggleAddPreCampaignButton: {
            const { isPreCampaignButton } = action.payload;
            return { ...state, isPreCampaignButton };
        }
        case actionTypes.ToggleCoursesActionButtons: {
            const { isCoursesActionButtons } = action.payload;
            return { ...state, isCoursesActionButtons };
        }

        case actionTypes.GridOrTableButton: {
            const { gridOrTable } = action.payload;
            return { ...state, gridOrTable };
        }

        case actionTypes.GridOrTableFilter: {
            const { gridOrTableFilter } = action.payload;
            return { ...state, gridOrTableFilter };
        }
        case actionTypes.Search: {
            const { searchInput_precampaign } = action.payload;
            return { ...state, searchInput_precampaign };
        }

        case actionTypes.SelectAllButton: {
            const { selectAllButton } = action.payload;
            return { ...state, selectAllButton };
        }

        case actionTypes.IsSelectedAll: {
            const { isSelectedAll } = action.payload;
            return { ...state, isSelectedAll };
        }

        case actionTypes.QuickActionsButton: {
            const { quickActionsButton } = action.payload;
            return { ...state, quickActionsButton };
        }

        case actionTypes.EditMoreInfoButton: {
            const { editMoreInfoButton } = action.payload;
            return { ...state, editMoreInfoButton };
        }

        case actionTypes.IsOpenEditMoreInfoButton: {
            const { isOpenEditMoreInfoButton } = action.payload;
            return { ...state, isOpenEditMoreInfoButton };
        }

        case actionTypes.StatusEditMoreInfoButton: {
            const { statusEditMoreInfoButton } = action.payload;
            return { ...state, statusEditMoreInfoButton };
        }

        case actionTypes.DuplicateButton: {
            const { duplicateButton } = action.payload;
            return { ...state, duplicateButton };
        }

        case actionTypes.StatusDuplicateButton: {
            const { statusDuplicateButton } = action.payload;
            return { ...state, statusDuplicateButton };
        }

        case actionTypes.StatusCloneModal: {
            const { statusCloneModal } = action.payload;
            return { ...state, statusCloneModal };
        }
        case actionTypes.StatusDeleteModal: {
            const { statusDeleteModal } = action.payload;
            return { ...state, statusDeleteModal };
        }

        case actionTypes.StatusLaunchModal: {
            const { statusLaunchModal } = action.payload;
            return { ...state, statusLaunchModal };
        }

        case actionTypes.SelectedLength: {
            const { selectedLength } = action.payload;
            return { ...state, selectedLength };
        }

        case actionTypes.SearchFilter: {
            const { searchFilter } = action.payload;
            return { ...state, searchFilter };
        }

        case actionTypes.ShowSearchFilter: {
            const { showSearchFilter } = action.payload;
            return { ...state, showSearchFilter };
        }

        case actionTypes.PreCampaignList: {
            const { preCampaignList } = action.payload;
            return { ...state, preCampaignList };
        }

        case actionTypes.PreCampaignListCountTotal: {
            const { preCampaignListCountTotal } = action.payload;
            return { ...state, preCampaignListCountTotal };
        }

        case actionTypes.PreCampaignListPageNumber: {
            const { preCampaignListPageNumber } = action.payload;
            return { ...state, preCampaignListPageNumber };
        }

        case actionTypes.PreCampaignListSelectedList: {
            const { preCampaignListSelectedList } = action.payload;
            return { ...state, preCampaignListSelectedList };
        }

        case actionTypes.EditInTable: {
            const { editInTable } = action.payload;
            return { ...state, editInTable };
        }

        case actionTypes.ToggleCourseEditButton: {
            const { isOpenCourseEditButton } = action.payload;
            return { ...state, isOpenCourseEditButton };
        }

        case actionTypes.IsCourseEditButtonExist: {
            const { isCourseEditButton } = action.payload;
            return { ...state, isCourseEditButton };
        }

        case actionTypes.SetSubheader: {
            const { subheader, path } = action.payload;
            return { ...state, subheader, path };
        }
        case actionTypes.SetLanguage: {
            const { language, path } = action.payload;
            return { ...state, language, path };
        }
        case actionTypes.SetCourseSlug: {
            const { courseSlug } = action.payload;
            return { ...state, courseSlug };
        }
        case actionTypes.SetSelectedCampaign: {
            const { selectedCampaign } = action.payload;
            return { ...state, selectedCampaign };
        }
        case actionTypes.SetCourseStatus: {
            const { courseStatus } = action.payload;
            return { ...state, courseStatus };
        }
        case actionTypes.SetCourseDetailLoading: {
            const { isCourseDetailLoading } = action.payload;
            return { ...state, isCourseDetailLoading };
        }
        case actionTypes.CoursesStatusFilter: {
            const { coursesStatusFilter } = action.payload;
            return { ...state, coursesStatusFilter };
        }
        case actionTypes.CourseBusinessSectorFilter: {
            const { courseBusinessSectorFilter } = action.payload;
            return { ...state, courseBusinessSectorFilter };
        }
        case actionTypes.CourseMasterTrainer: {
            const { courseMasterTrainerFilter } = action.payload;
            return { ...state, courseMasterTrainerFilter };
        }
        case actionTypes.CourseDesignationFilter: {
            const { courseDesignationFilter } = action.payload;
            return { ...state, courseDesignationFilter };
        }
        case actionTypes.CourseLanguageFilter: {
            const { courseLanguageFilter } = action.payload;
            return { ...state, courseLanguageFilter };
        }
        case actionTypes.CourseLevelTypeFilter: {
            const { courseLevelTypeFilter } = action.payload;
            return { ...state, courseLevelTypeFilter };
        }
        case actionTypes.CourseTypeFilter: {
            const { courseTypeFilter } = action.payload;
            return { ...state, courseTypeFilter };
        }
        case actionTypes.CourseAcademy: {
            const { courseAcademyFilter } = action.payload;
            return { ...state, courseAcademyFilter };
        }
        case actionTypes.CourseProjectManager: {
            const { courseProjectManagerFilter } = action.payload;
            return { ...state, courseProjectManagerFilter };
        }
        case actionTypes.CourseRevenueFilter: {
            const { courseRevenueFilter } = action.payload;
            return { ...state, courseRevenueFilter };
        }
        case actionTypes.CourseEvaluationScore: {
            const { courseEvaluationScore } = action.payload;
            return { ...state, courseEvaluationScore };
        }
        case actionTypes.CourseNumberOfDelegation: {
            const { courseNumberOfDelegation } = action.payload;
            return { ...state, courseNumberOfDelegation };
        }
        case actionTypes.CourseLVTPrice: {
            const { courseLVTPrice } = action.payload;
            return { ...state, courseLVTPrice };
        }
        case actionTypes.CourseF2FPrice: {
            const { courseF2Fprice } = action.payload;
            return { ...state, courseF2Fprice };
        }
        case actionTypes.CourseSubsidized: {
            const { subsidizedByHrdf } = action.payload;
            return { ...state, subsidizedByHrdf };
        }
        case actionTypes.CourseRefund: {
            const { refundType } = action.payload;
            return { ...state, refundType };
        }
        case actionTypes.CourseDetailExcel: {
            const { coursesDetailExcel } = action.payload;
            return { ...state, coursesDetailExcel };
        }
        case actionTypes.CourseExcelExport: {
            const { courseExcelExport } = action.payload;
            return { ...state, courseExcelExport };
        }
        case actionTypes.ShowPrecampaignsActions: {
            const { showPrecampaignsActions } = action.payload;
            return { ...state, showPrecampaignsActions };
        }
        case actionTypes.ShowCourseDetailActions: {
            const { showCourseDetailActions } = action.payload;
            return { ...state, showCourseDetailActions };
        }
        case actionTypes.ViewType: {
            const { viewType } = action.payload;
            return {
                ...state,
                viewType,
            };
        }
        case actionTypes.IsAttendanceDrawerOpen: {
            const { isAttendanceDrawerOpen } = action.payload;
            return {
                ...state,
                isAttendanceDrawerOpen,
            };
        }
        case actionTypes.SelectedAttendanceFilter: {
            const { selectedAttendanceFilter } = action.payload;
            return {
                ...state,
                selectedAttendanceFilter,
            };
        }
        case actionTypes.AttendanceListForFilter: {
            const { attendanceListForFilter } = action.payload;
            return {
                ...state,
                attendanceListForFilter,
            };
        }
        case actionTypes.selectedAttendanceListFromFilter: {
            const { selectedAttendanceListFromFilter } = action.payload;
            return {
                ...state,
                selectedAttendanceListFromFilter,
            };
        }
        case actionTypes.IsAttendanceLoading: {
            const { isAttendanceLoading } = action.payload;
            return {
                ...state,
                isAttendanceLoading,
            };
        }
        case actionTypes.ShowAttendanceSidebar: {
            const { showAttendanceSidebar } = action.payload;
            return {
                ...state,
                showAttendanceSidebar,
            };
        }
        case actionTypes.IsFilterByAttendanceModalShown: {
            const { isFilterByAttendanceModalShown } = action.payload;
            return {
                ...state,
                isFilterByAttendanceModalShown,
            };
        }
        case actionTypes.AttendanceDetailModalId: {
            const { attendanceDetailModalId } = action.payload;
            return {
                ...state,
                attendanceDetailModalId,
            };
        }
        case actionTypes.IsCampaignOver: {
            const { isCampaignOver } = action.payload;
            return {
                ...state,
                isCampaignOver,
            };
        }
        default:
            return state;
    }
};

export const actions = {
    setCoursesFilter: (coursesFilter) => ({
        type: actionTypes.CoursesFilter,
        payload: { coursesFilter },
    }),
    toggleAddPreCampaignButton: (isPreCampaignButton) => ({
        type: actionTypes.ToggleAddPreCampaignButton,
        payload: { isPreCampaignButton },
    }),

    toggleCoursesActionButtons: (isCoursesActionButtons) => ({
        type: actionTypes.ToggleCoursesActionButtons,
        payload: { isCoursesActionButtons },
    }),
    gridOrTableButton: (gridOrTable) => ({
        type: actionTypes.GridOrTableButton,
        payload: { gridOrTable },
    }),

    setGridOrTableFilter_precampaign: (gridOrTableFilter) => ({
        type: actionTypes.GridOrTableFilter,
        payload: { gridOrTableFilter },
    }),

    searchPrecampaign: (searchInput_precampaign) => ({
        type: actionTypes.Search,
        payload: { searchInput_precampaign },
    }),

    setSelectAllButton: (selectAllButton) => ({
        type: actionTypes.SelectAllButton,
        payload: { selectAllButton },
    }),

    toggleSelectedAll: (isSelectedAll) => ({
        type: actionTypes.IsSelectedAll,
        payload: { isSelectedAll },
    }),

    setQuickActionsButton: (quickActionsButton) => ({
        type: actionTypes.QuickActionsButton,
        payload: { quickActionsButton },
    }),

    setEditMoreInfoButton: (editMoreInfoButton) => ({
        type: actionTypes.EditMoreInfoButton,
        payload: { editMoreInfoButton },
    }),

    toggleEditMoreInfoButton: (isOpenEditMoreInfoButton) => ({
        type: actionTypes.IsOpenEditMoreInfoButton,
        payload: { isOpenEditMoreInfoButton },
    }),

    setStatusEditMoreInfoButton: (statusEditMoreInfoButton) => ({
        type: actionTypes.StatusEditMoreInfoButton,
        payload: { statusEditMoreInfoButton },
    }),

    setDuplicateButton: (duplicateButton) => ({
        type: actionTypes.DuplicateButton,
        payload: { duplicateButton },
    }),

    setStatusDuplicateButton: (statusDuplicateButton) => ({
        type: actionTypes.StatusDuplicateButton,
        payload: { statusDuplicateButton },
    }),

    setStatusCloneModal: (statusCloneModal) => ({
        type: actionTypes.StatusCloneModal,
        payload: { statusCloneModal },
    }),

    setStatusDeleteModal: (statusDeleteModal) => ({
        type: actionTypes.StatusDeleteModal,
        payload: { statusDeleteModal },
    }),

    setStatusLaunchModal: (statusLaunchModal) => ({
        type: actionTypes.StatusLaunchModal,
        payload: { statusLaunchModal },
    }),

    setSelectedLength: (selectedLength) => ({
        type: actionTypes.SelectedLength,
        payload: { selectedLength },
    }),

    setSearchFilter: (searchFilter) => ({
        type: actionTypes.SearchFilter,
        payload: { searchFilter },
    }),

    setShowSearchFilter: (showSearchFilter) => ({
        type: actionTypes.ShowSearchFilter,
        payload: { showSearchFilter },
    }),

    setPreCampaignList: (preCampaignList) => ({
        type: actionTypes.PreCampaignList,
        payload: { preCampaignList },
    }),

    setPreCampaignListCountTotal: (preCampaignListCountTotal) => ({
        type: actionTypes.PreCampaignListCountTotal,
        payload: { preCampaignListCountTotal },
    }),

    setPreCampaignListPageNumber: (preCampaignListPageNumber) => ({
        type: actionTypes.PreCampaignListPageNumber,
        payload: { preCampaignListPageNumber },
    }),

    setPreCampaignListSelectedList: (preCampaignListSelectedList) => ({
        type: actionTypes.PreCampaignListSelectedList,
        payload: { preCampaignListSelectedList },
    }),

    setEditInTable: (editInTable) => ({
        type: actionTypes.EditInTable,
        payload: { editInTable },
    }),

    isCourseEditButtonExist: (isCourseEditButton) => ({
        type: actionTypes.IsCourseEditButtonExist,
        payload: { isCourseEditButton },
    }),

    toggleCourseEditButton: (isOpenCourseEditButton) => ({
        type: actionTypes.ToggleCourseEditButton,
        payload: { isOpenCourseEditButton },
    }),

    setSubheader: (subheader, path) => ({
        type: actionTypes.SetSubheader,
        payload: { subheader, path },
    }),

    setLanguage: (language, path) => ({
        type: actionTypes.SetLanguage,
        payload: { language, path },
    }),
    setCourseSlug: (courseSlug) => ({
        type: actionTypes.SetCourseSlug,
        payload: { courseSlug },
    }),
    setSelectedCampaign: (selectedCampaign) => ({
        type: actionTypes.SetSelectedCampaign,
        payload: { selectedCampaign },
    }),
    setCourseStatus: (courseStatus) => ({
        type: actionTypes.SetCourseStatus,
        payload: { courseStatus },
    }),
    setCourseDetailLoading: (isCourseDetailLoading) => ({
        type: actionTypes.SetCourseDetailLoading,
        payload: { isCourseDetailLoading },
    }),
    setCourseStatusFilter: (coursesStatusFilter) => ({
        type: actionTypes.CoursesStatusFilter,
        payload: { coursesStatusFilter },
    }),
    setCourseBusinessSectorFilter: (courseBusinessSectorFilter) => ({
        type: actionTypes.CourseBusinessSectorFilter,
        payload: { courseBusinessSectorFilter },
    }),
    setCourseMasterTrainer: (courseMasterTrainerFilter) => ({
        type: actionTypes.CourseMasterTrainer,
        payload: { courseMasterTrainerFilter },
    }),
    setCourseDesignationFilter: (courseDesignationFilter) => ({
        type: actionTypes.CourseDesignationFilter,
        payload: { courseDesignationFilter },
    }),
    setCourseLanguageFilter: (courseLanguageFilter) => ({
        type: actionTypes.CourseLanguageFilter,
        payload: { courseLanguageFilter },
    }),
    setCourseLevelTypeFilter: (courseLevelTypeFilter) => ({
        type: actionTypes.CourseLevelTypeFilter,
        payload: { courseLevelTypeFilter },
    }),
    setCourseTypeFilter: (courseTypeFilter) => ({
        type: actionTypes.CourseTypeFilter,
        payload: { courseTypeFilter },
    }),
    setCourseAcademy: (courseAcademyFilter) => ({
        type: actionTypes.CourseAcademy,
        payload: { courseAcademyFilter },
    }),
    setCourseProjectManager: (courseProjectManagerFilter) => ({
        type: actionTypes.CourseProjectManager,
        payload: { courseProjectManagerFilter },
    }),
    setCourseRevenueFilter: (courseRevenueFilter) => ({
        type: actionTypes.CourseRevenueFilter,
        payload: { courseRevenueFilter },
    }),
    setCourseEvaluationScore: (courseEvaluationScore) => ({
        type: actionTypes.CourseEvaluationScore,
        payload: { courseEvaluationScore },
    }),
    setCourseNumberOfDelegation: (courseNumberOfDelegation) => ({
        type: actionTypes.CourseNumberOfDelegation,
        payload: { courseNumberOfDelegation },
    }),
    setCourseLVTPrice: (courseLVTPrice) => ({
        type: actionTypes.CourseLVTPrice,
        payload: { courseLVTPrice },
    }),
    setCourseF2FPrice: (courseF2Fprice) => ({
        type: actionTypes.CourseF2FPrice,
        payload: { courseF2Fprice },
    }),
    setCourseSubsidized: (subsidizedByHrdf) => ({
        type: actionTypes.CourseSubsidized,
        payload: { subsidizedByHrdf },
    }),
    setRefundType: (refundType) => ({
        type: actionTypes.CourseRefund,
        payload: { refundType },
    }),
    setCoursesDetailExcel: (coursesDetailExcel) => ({
        type: actionTypes.CourseDetailExcel,
        payload: { coursesDetailExcel },
    }),
    setCoursesExcelExport: (courseExcelExport) => ({
        type: actionTypes.CourseExcelExport,
        payload: { courseExcelExport },
    }),
    setShowPrecampaignsActions: (showPrecampaignsActions) => ({
        type: actionTypes.ShowPrecampaignsActions,
        payload: { showPrecampaignsActions },
    }),
    setShowCourseDetailActions: (showCourseDetailActions) => ({
        type: actionTypes.ShowCourseDetailActions,
        payload: { showCourseDetailActions },
    }),
    setViewType: (viewType) => ({
        type: actionTypes.ViewType,
        payload: { viewType },
    }),
    setIsAttendanceDrawerOpen: (isAttendanceDrawerOpen) => ({
        type: actionTypes.IsAttendanceDrawerOpen,
        payload: { isAttendanceDrawerOpen },
    }),
    setSelectedAttendanceFilter: (selectedAttendanceFilter) => ({
        type: actionTypes.SelectedAttendanceFilter,
        payload: { selectedAttendanceFilter },
    }),
    setAttendanceListForFilter: (attendanceListForFilter) => ({
        type: actionTypes.AttendanceListForFilter,
        payload: { attendanceListForFilter },
    }),
    setSelectedAttendanceListFromFilter: (selectedAttendanceListFromFilter) => ({
        type: actionTypes.selectedAttendanceListFromFilter,
        payload: { selectedAttendanceListFromFilter },
    }),
    setAttendanceLoading: (isAttendanceLoading) => ({
        type: actionTypes.IsAttendanceLoading,
        payload: { isAttendanceLoading },
    }),
    setShowAttendanceSidebar: (showAttendanceSidebar) => ({
        type: actionTypes.ShowAttendanceSidebar,
        payload: { showAttendanceSidebar },
    }),
    setIsFilterByAttendanceModalShown: (isFilterByAttendanceModalShown) => ({
        type: actionTypes.IsFilterByAttendanceModalShown,
        payload: { isFilterByAttendanceModalShown },
    }),
    setAttendanceDetailModalId: (attendanceDetailModalId) => ({
        type: actionTypes.AttendanceDetailModalId,
        payload: { attendanceDetailModalId },
    }),
    setIsCampaignOver: (isCampaignOver) => ({
        type: actionTypes.IsCampaignOver,
        payload: { isCampaignOver },
    }),
};
