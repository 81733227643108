import ButtonWithText from "app/popleads/components/ui-kit/Button";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as trainingRedux from "../../modules/training/redux/trainingRedux";
import TrainersFilter from "app/popleads/modules/filters/TrainersFilter";

const TrainersPageActions = (props) => {
    return (
        <div className="d-flex align-items-center w-100" style={{ gap: "8px" }}>
            {props.isOpenCalendarButton && (
                <Link to="/trainers/calendar/" style={{ padding: 0 }}>
                    <ButtonWithText variant="checkboxActive" leftIcon="calendar">
                        Calendar
                    </ButtonWithText>
                </Link>
            )}

            {props.isOpenAddTrainerButton && (
                <ButtonWithText
                    variant="successInverted"
                    leftIcon="plus"
                    onClick={() => {
                        this.props.toggleAccountEditButton(true);
                    }}
                >
                    Add New Trainer
                </ButtonWithText>
            )}

            {props.trainersFilter && <TrainersFilter />}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isOpenAddTrainerButton: state.training.isOpenAddTrainerButton,
        isOpenCalendarButton: state.training.isOpenCalendarButton,
        isTrainerEditPermission: state.training.isTrainerEditPermission,
        trainersFilter: state.training.trainersFilter,
    };
};

const mapDispatchToProps = {
    ...trainingRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainersPageActions);
