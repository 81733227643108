import Tabs from "app/popleads/components/ui-kit/Tabs";
import React, { useState } from "react";
import { connect } from "react-redux";

function TabsComponent() {
    const options = [{ value: "Option 1" }, { value: "Option 2" }, { value: "Option 3" }];
    const [option, selectOption] = useState(options[0].value);

    return (
        <div className="card card-custom mt-5">
            <div className="card-header">
                <h3 className="card-title">Tabs</h3>
            </div>
            <div className="card-body">
                <Tabs options={options} selected={option} setSelected={selectOption} />

                <div className="mt-3">
                    <p>Selected Option: {option}</p>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TabsComponent);
