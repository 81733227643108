import React from "react";
import { NavLink } from "react-router-dom";
import CoursesFilterDrawer from "../drawers/CoursesFilterDrawer";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/popleads/modules/campaigns/redux/campaignsRedux";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import SVG from "react-inlinesvg";
import Toggle from "app/popleads/components/ui-kit/Toggle";
import ChatGptDrawer from "app/popleads/components/Chat/ChatGptDrawer";

const CourseListActions = () => {
    const { user } = useSelector((state) => state.auth);
    const { gridOrTable } = useSelector((state) => state.campaigns);

    const dispatch = useDispatch();

    const options = [
        {
            value: "grid",
            activeVariant: "primary",
            icon: "grid",
            tooltip: "Grid View",
        },
        {
            value: "table",
            activeVariant: "primary",
            icon: "list",
            tooltip: "Table View",
        },
    ];

    return (
        <div className="d-flex align-items-center" style={{ gap: "8px" }}>
            {user?.permissions?.CAMPAIGNTEMPLATE_MANAGER === 1 && (
                <ButtonWithText variant="successActive" onClick={() => dispatch(actions.setCoursesExcelExport(true))}>
                    <SVG src="/media/svg/popleads/export_excel_icon.svg" />
                </ButtonWithText>
            )}
            <CoursesFilterDrawer />
            <Toggle
                selected={gridOrTable}
                setSelected={(viewType) => dispatch(actions.gridOrTableButton(viewType))}
                options={options}
            />
            {user?.permissions?.CAMPAIGNTEMPLATE_MANAGER === 1 && (
                <NavLink to="/campaigns/courses/add" className="cardToolbarMuted">
                    {/* FOR LG SCREEN AND ABOVE */}
                    <ButtonWithText variant="successInverted" leftIcon="plus" className="d-none d-md-block">
                        Add New Course
                    </ButtonWithText>
                    {/* FOR MOBILE + TABLET */}
                    <ButtonWithText variant="successInverted" leftIcon="plus" className="d-block d-md-none">
                        Add
                    </ButtonWithText>
                </NavLink>
            )}
            <div
                style={{
                    width: "40px",
                    height: "40px",
                    overflow: "hidden",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "40px",
                    minHeight: "40px",
                }}
            >
                <ChatGptDrawer className={"m-0 btn-borderless"} />
            </div>
        </div>
    );
};

export default CourseListActions;
