import React, { useState, useEffect } from "react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import * as reportsRedux from "../reports/redux/reportsRedux";
import * as authRedux from "../../../modules/Auth/_redux/authRedux";
import { get, post } from "../../networking/RequestService";
import { silentlyParseJSON } from "../../app/functions/util";
import LoadingSpin from "../../app/partials/LoadingSpin";
import DepartmentFilter from "./components/DepartmentFilter";
import EmployeeFilter from "./components/EmployeeFilter";
import SaveFilter from "./components/SaveFilter";
import SimpleModal from "../modal/simpleModal";
import CustomDrawer, { DrawerBody, DrawerContent, DrawerHeader } from "app/popleads/components/drawer/CustomDrawer";
import useDisclosure from "app/popleads/hooks/useDisclosure";
import { TooltipOverlay } from "app/popleads/components/TooltipOverlay";
import DateFilter from "./components/DateFilter";
import styles from "../../../popleads/assets/css/design_3.0/CustomDatePicker.module.scss";
import { isSalesFullPageLoading } from "../../app/functions/util";
import InquiryTypeFilter from "./components/InquiryTypeFilter";
import FranchiseFilter from "./components/FranchiseFilter";
import ButtonWithText from "app/popleads/components/ui-kit/Button";

const SalesFilters = ({ user, employees, departments, dates, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [usersFilters, setUsersFilters] = useState([]);
    const [selectedFilterId, setSelectedFilterId] = useState(null);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [deletedFilterId, setDeletedFilterId] = useState(null);

    const { isOpen, onClose, onToggle } = useDisclosure();

    const onFilterSelected = (filter) => {
        const { id, filters } = filter;

        setSelectedFilterId(id);

        props.selectEmployee(filters.employees);
        props.selectDepartment(filters.departments);
        props.selectDate({
            ...filters.dates,
            startDate: new Date(filters.dates.startDate),
            endDate: new Date(filters.dates.endDate),
        });
    };

    const saveNewFilter = async (name) => {
        try {
            setIsLoading(true);
            const newId = uuidv4();
            const newFilter = {
                id: newId,
                name: name,
                createdTime: moment().valueOf(),
                filters: {
                    employees,
                    departments,
                    dates,
                },
            };
            const reqObj = {
                fn: "setUserSettings",
                settingsKey: "sales_filters_settings",
                settingsValue: JSON.stringify([newFilter, ...usersFilters]),
            };
            await post(reqObj);
            const { data } = await get("getLoggedinUser");
            if (data.user) {
                props.setUser(data.user);
            }
            setSelectedFilterId(newId);
        } catch (error) {
            // console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteSelectedFilter = async () => {
        try {
            setIsLoading(true);
            const updatedArr = usersFilters.filter((item) => item.id !== deletedFilterId);
            const reqObj = {
                fn: "setUserSettings",
                settingsKey: "sales_filters_settings",
                settingsValue: JSON.stringify(updatedArr),
            };
            await post(reqObj);
            const { data } = await get("getLoggedinUser");
            if (data.user) {
                props.setUser(data.user);
            }
            clearAllFilters();
        } catch (error) {
            // console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const clearAllFilters = () => {
        setSelectedFilterId(null);

        props.selectEmployee([]);
        props.selectDepartment([]);
        props.selectDate({
            startDate: moment()
                .startOf("year")
                .toDate(),
            endDate: moment()
                .endOf("year")
                .toDate(),
            predefinedTag: "thisYear",
            dateString: "This Year",
        });
    };

    useEffect(() => {
        setUsersFilters(silentlyParseJSON(user.salesFilters, "[]"));
    }, [user.salesFilters]);

    useEffect(() => {
        if (
            (employees && employees.length > 0) ||
            (departments && departments.length > 0) ||
            (dates && dates.predefinedTag !== "thisYear")
        ) {
            setIsFilterApplied(true);
        } else {
            setIsFilterApplied(false);
        }
    }, [employees, departments, dates]);

    return (
        <>
            {deletedFilterId && (
                <SimpleModal
                    title=""
                    body="Are you sure you want to delete this filter?<br>"
                    className="d-flex flex-column align-items-center"
                    bodyClass="font-weight-semibold font-size-20px"
                    primaryButton="Delete"
                    secondaryButton="Cancel"
                    onClose={(resp) => {
                        if (resp === "OK") deleteSelectedFilter();
                        setDeletedFilterId(null);
                    }}
                />
            )}
            <TooltipOverlay placement="bottom" tooltip="Filters">
                <ButtonWithText icon={"filter"} variant={"checkboxActive"} onClick={onToggle}></ButtonWithText>
            </TooltipOverlay>
            <CustomDrawer position="right" size="md" open={isOpen} onClose={onClose}>
                <DrawerContent isLoading={isSalesFullPageLoading(props.salesPageLoading)}>
                    <DrawerHeader onClose={onClose} sx={{ border: "none" }}>
                        <div>Filters</div>
                    </DrawerHeader>
                    <DrawerBody sx={{ paddingTop: 0 }}>
                        {isLoading ? (
                            <LoadingSpin />
                        ) : (
                            <SaveFilter
                                usersFilters={usersFilters}
                                isFilterApplied={isFilterApplied}
                                selectedFilterId={selectedFilterId}
                                onFilterSelected={onFilterSelected}
                                saveNewFilter={saveNewFilter}
                                deleteSelectedFilter={(id) => setDeletedFilterId(id)}
                                clearAllFilters={clearAllFilters}
                            />
                        )}
                        {props.employeeFilter && <EmployeeFilter isMultiSelect={true} />}
                        {props.departmentFilter && <DepartmentFilter isMultiSelect={true} />}
                        {props.inquiryTypeFilter && <InquiryTypeFilter isMultiSelect={true} />}
                        {props.franchiseFilter && <FranchiseFilter isMultiSelect={true} />}
                        <DateFilter classname={`${styles.rdrDateRangePickerWrapper} mt-4 mb-8`} drawerLoading={false} />
                    </DrawerBody>
                </DrawerContent>
            </CustomDrawer>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        employees: state.reports.selectedEmployee,
        departments: state.reports.selectedDepartment,
        dates: state.reports.selectedDate,
        salesPageLoading: state.reports.salesPageLoading,
        employeeFilter: state.app.employeeFilter,
        departmentFilter: state.app.departmentFilter,
        inquiryTypeFilter: state.app.inquiryTypeFilter,
        franchiseFilter: state.app.franchiseFilter,
    };
};

const mapDispatchToProps = {
    ...authRedux.actions,
    ...reportsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesFilters);
