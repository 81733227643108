import React from "react";
import { TooltipOverlay } from "app/popleads/components/TooltipOverlay";
import CustomDrawer, { DrawerBody, DrawerContent, DrawerHeader } from "app/popleads/components/drawer/CustomDrawer";
import useDisclosure from "app/popleads/hooks/useDisclosure";
import NewFilter from "../../modules/filters/NewFilter";
import ButtonWithText from "app/popleads/components/ui-kit/Button";

const PrecampaignsFilterDrawer = () => {
    const { isOpen, onClose, onToggle } = useDisclosure();

    return (
        <>
            <TooltipOverlay placement="bottom" tooltip="Filters">
                <ButtonWithText icon={"filter"} variant={"checkboxActive"} onClick={onToggle} />
            </TooltipOverlay>
            <CustomDrawer position="right" size="md" open={isOpen} onClose={onClose}>
                <DrawerContent>
                    <DrawerHeader onClose={onClose}>Filters</DrawerHeader>
                    <DrawerBody>
                        <NewFilter />
                    </DrawerBody>
                </DrawerContent>
            </CustomDrawer>
        </>
    );
};

export default PrecampaignsFilterDrawer;
