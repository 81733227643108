// colors.js
export const colors = {
    primary: {
        50: "#F2F8FB",
        100: "#E5F2F8",
        200: "#CCE6F2",
        300: "#B2DAEB",
        400: "#99CEE5",
        500: "#7FC2DE",
        600: "#66B5D8",
        700: "#4CA9D1",
        800: "#329DCB",
        900: "#0085BE",
        950: "#006A98",
    },
    neutral: {
        50: "#FBFCFC",
        100: "#EEF2F3",
        200: "#DEE5E7",
        300: "#C2CCCF",
        400: "#A7B2B7",
        500: "#8B999F",
        600: "#6F8086",
        700: "#53676E",
        800: "#384D56",
        900: "#1C343E",
        950: "#001B26",
    },
    danger: {
        50: "#FDF5F5",
        100: "#FCECEC",
        200: "#F9D9D9",
        300: "#F6C6C6",
        400: "#F3B4B4",
        500: "#F0A1A1",
        600: "#ED8E8E",
        700: "#EA7C7C",
        800: "#E76969",
        900: "#E24444",
        950: "#B43636",
    },
    warning: {
        50: "#FCF8F6",
        100: "#F9EFEA",
        200: "#F4DFD6",
        300: "#EFCFC1",
        400: "#EABFAD",
        500: "#E5AF98",
        600: "#DF9F84",
        700: "#DA8F6F",
        800: "#D57F5A",
        900: "#CB6032",
        950: "#A24C28",
    },
    success: {
        50: "#F2F8F7",
        100: "#E5F3F0",
        200: "#CCE6E1",
        300: "#B2DAD2",
        400: "#99CEC3",
        500: "#7FC2B4",
        600: "#65B5A5",
        700: "#4CA996",
        800: "#329D87",
        900: "#00856A",
        950: "#006A54",
    },
    other: {
        50: "#F9F2FD",
        100: "#F3E6FB",
        200: "#E8CEF7",
        300: "#DCB6F3",
        400: "#D19EEF",
        500: "#C586EC",
        600: "#BA6EE8",
        700: "#AE56E4",
        800: "#A33EE0",
        900: "#8C0ED9",
        950: "#700BAD",
    },
};
