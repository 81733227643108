import React from "react";
import { Dropdown } from "react-bootstrap";
import { CustomToggleIcon } from "../utils";
import * as activitiesRedux from "../redux/activitiesRedux";
import { connect } from "react-redux";
import Select from "../../../app/partials/Select";
import useIsLessThan from "../../../hooks/useIsLessThan";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import useDisclosure from "app/popleads/hooks/useDisclosure";

const FiterByAssigneeDropdown = ({
    companyUsersAndGroupsData,
    filterByAssignee,
    setFilterByAssignee,
    setSelectedFilterByOptions,
}) => {
    const options = companyUsersAndGroupsData.map((user) => ({ label: user.name, value: user.id }));
    const isLessThanBsXl = useIsLessThan("bootstrap-md");
    const { onToggle } = useDisclosure();

    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggleIcon} id="dropdown-custom-components">
                <ButtonWithText
                    tooltip="Filter by Assignee"
                    icon={"user-check"}
                    variant={filterByAssignee?.length > 0 ? "checkboxActive" : "checkboxInActive"}
                    onClick={onToggle}
                />
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="my-1 px-3 py-2 dropdown-menu"
                style={isLessThanBsXl ? { width: "250px" } : { minWidth: "350px" }}
            >
                <Select
                    className="activitySelectInput"
                    placeholder="Filter by Assignee"
                    options={options}
                    onChange={(assignees) => {
                        setFilterByAssignee(assignees);
                        if (assignees?.length > 0) setSelectedFilterByOptions([]);
                    }}
                    value={filterByAssignee}
                    isMulti
                />
            </Dropdown.Menu>
        </Dropdown>
    );
};

const mapStateToProps = (state) => {
    return {
        filterByAssignee: state.activities.filterByAssignee,
        companyUsersAndGroupsData: state.activities.companyUsersAndGroupsData,
    };
};

const mapDispatchToProps = {
    ...activitiesRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiterByAssigneeDropdown);
