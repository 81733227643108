import React from "react";
import { connect } from "react-redux";
import * as appRedux from "../../../../../app/popleads/app/redux/appRedux";
import * as surveyRedux from "../../../../../app/popleads/modules/survey/redux/surveyRedux";
import * as util from "../../../../../app/popleads/app/functions/util";
import { post } from "../../../../../app/popleads/networking/RequestService";
import moment from "moment";
// import Button from "../../../../../app/popleads/components/Button";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import SVG from "react-inlinesvg";

class SurveyBar extends React.Component {
    downloadExcel(surveyId, campaignId) {
        let requestObj = {
            fn: "exportSurveyAnswer",
            surveyId,
            campaignId,
        };

        post(requestObj)
            .then(({ data: { fileName } }) => {
                let url = window.location.origin.includes("localhost") ? "https://localhost:8443/PopLeads" : "";
                let authToken = this.props.authToken;
                if (!authToken) authToken = util.getCookieValue(util.getCookieName());
                let exportUrl = url + "/api/ws/" + fileName + "/downloadExport?token=" + authToken;
                window.open(exportUrl, "_blank");
            })
            .catch((error) => {
                // console.log("error: ", error);
                this.setState({
                    errorText: "Ooops! An error occured.",
                });
            });
    }

    downloadPDF(surveyId, campaignId, campaignName) {
        const baseURL = window.location.href;

        let name = campaignName + "-survey-" + moment().format("DD-MM-YYYY") + ".pdf";

        let authToken = this.props.authToken;
        if (!authToken) authToken = util.getCookieValue(util.getCookieName());

        const downloadUrl = baseURL.includes("localhost")
            ? "https://localhost:8443/PopLeads"
            : window.location.protocol + "//" + window.location.host;

        let url =
            `${downloadUrl}/api/ws/download/${name}?token=${authToken}&url=${window.location.protocol}//${window.location.host}/pdf/evaluation-report?sid=${authToken}` +
            encodeURIComponent(`&campaignId=${campaignId}&surveyId=${surveyId}`);

        window.open(url, "_blank");
    }

    async copyTheLink(surveyId, campaignId) {
        post({
            fn: "getCampaignDelegateList",
            surveyId,
            campaignId,
        })
            .then(({ data: { delegateList } }) => {
                let requestObj = {
                    fn: "getUniqueLinkForSurvey",
                    surveyId,
                    campaignId,
                    profileId: delegateList[0].profileId,
                };

                post(requestObj)
                    .then(({ data: { link } }) => {
                        const origin = window.location.origin;
                        const url =
                            (origin.includes("localhost")
                                ? "https://localhost:8443/PopLeads/api/ws/createQrCode"
                                : origin + "/api/ws/createQrCode") +
                            "?url=" +
                            link;
                        window.open(url, "_blank");
                    })
                    .catch((error) => {
                        // console.log("error: ", error);
                        this.setState({
                            errorText: "Ooops! An error occured.",
                        });
                    });
            })
            .catch((error) => {
                // console.log("error: ", error);
                this.setState({
                    errorText: "Ooops! An error occured.",
                });
            });
    }

    render() {
        if (this.props.surveyId && this.props.campaignId) {
            return (
                <div className="d-flex mx-2" style={{ gap: "8px" }}>
                    <ButtonWithText
                        variant="successActive"
                        tooltip="Download Report"
                        onClick={() => this.downloadExcel(this.props.surveyId, this.props.campaignId)}
                    >
                        <SVG src="/media/svg/popleads/export_excel_icon.svg" />
                    </ButtonWithText>

                    {this.props.campaignName && (
                        <ButtonWithText
                            variant="dangerActive"
                            tooltip="Download Delegate Report"
                            onClick={() => {
                                this.downloadPDF(this.props.surveyId, this.props.campaignId, this.props.campaignName);
                            }}
                        >
                            <SVG src="/media/svg/popleads/pdf.svg" />
                        </ButtonWithText>
                    )}

                    {this.props.campaignType === "In-house" && (
                        <ButtonWithText
                            variant="neutralActive"
                            tooltip="Open QR"
                            onClick={() =>
                                this.copyTheLink(
                                    this.props.surveyId,
                                    this.props.campaignId
                                    // this.state.delegateList[0]
                                )
                            }
                        >
                            <SVG src="/media/svg/popleads/qr_code.svg" />
                        </ButtonWithText>
                    )}
                </div>
            );
        }
        return null;
    }
}

const mapDispatchToProps = {
    ...appRedux.actions,
    ...surveyRedux.actions,
};

const mapStateToProps = (state) => {
    return {
        authToken: state.auth.authToken,
        surveyId: state.survey.surveyId,
        campaignId: state.survey.campaignId,
        campaignName: state.survey.campaignName,
        campaignType: state.survey.campaignType,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyBar);
