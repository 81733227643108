import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import * as activitiesRedux from "../../modules/activities/redux/activitiesRedux";
import ActivityDrawerFilters from "../../modules/activities/ActivityDrawerFilters";
import SortDropdown from "../../modules/activities/customDropdowns/SortDropdown";
import FilterByDropdown from "app/popleads/modules/activities/customDropdowns/FilterByDropdown";
import FilterByAssigneeDropdown from "app/popleads/modules/activities/customDropdowns/FilterByAssigneeDropdown";
import { campaignFiltersToSendAPI, getWindowSize } from "app/popleads/modules/activities/utils";
import { TABLET_BREAKPOINT, XXL_BREAKPOINT } from "app/popleads/app/contants";
import ChatGptDrawer from "app/popleads/components/Chat/ChatGptDrawer";
import { post } from "app/popleads/networking/RequestService";
import moment from "moment";
import { toast } from "react-toastify";
import InputWithIcon from "app/popleads/components/ui-kit/Input";
import Toggle from "app/popleads/components/ui-kit/Toggle";
import ButtonWithText from "app/popleads/components/ui-kit/Button";

const ROWS_PER_PAGE = 20;
const GROUP_ROWS_PER_PAGE = 10;
const toggleOptions = [
    {
        value: "board",
        activeVariant: "primary",
        icon: "grid",
        tooltip: "Grid View",
    },
    {
        value: "table",
        activeVariant: "primary",
        icon: "list",
        tooltip: "Board View",
    },
];
const ActivitiesActions = (props) => {
    // const userPreferences = silentlyParseJSON(props.user.activityPageConfig, "{}");
    const { width } = getWindowSize();
    const greaterThanTabletSize = width > TABLET_BREAKPOINT;
    const isMobile = width <= XXL_BREAKPOINT;
    const { isFromMobileApp } = useSelector((state) => state.app);
    const campaignFilters = campaignFiltersToSendAPI(
        props.selectedCampaignFilter,
        props.selectedCampaignListFromFilter
    );
    const dispatch = useDispatch();

    const handleExcelClick = async () => {
        const reqObj = {
            fn: "exportExcelForActivities",
            groupBy: props.selectedGroupByOption.value,
            filterByPriority: props.priorityFilter.map((item) => item.value),
            userAssigneeId: props.filterByAssignee?.map((assignee) => assignee.value),
            creatorId: props.filterByCreator?.map((creator) => creator.value),
            search: props.searchQuery,
            filterBy: props.selectedFilterByOptions.map((option) => option.value),
            type: props.selectedActivityTypes.map((type) => type.label),
            ...(campaignFilters && { ...campaignFilters }),
            start: 1,
            rowsPerPage: ROWS_PER_PAGE,
            ...(props.selectedGroupByOption.value && { groupBySort: props.selectedGroupBySortOption.value }),
            ...(props.selectedGroupByOption.value === "dueDate" && {
                groupStartPage: 1,
                groupRowsPerPage: GROUP_ROWS_PER_PAGE,
            }),
            ...(props.activityDateFilter[0].startDate && {
                startDate: moment(props.activityDateFilter[0].startDate, "DD/MM/YYYY").format("yyyy-MM-DD"),
            }),
            ...(props.activityDateFilter[0].endDate && {
                endDate: moment(props.activityDateFilter[0].endDate, "DD/MM/YYYY").format("yyyy-MM-DD"),
            }),
            ...(props.viewType === "mindmap" && {
                isMindMap: 1,
                isMindMapActionPlan: props.isMindmapActionPlanChecked ? 1 : 0,
                isMindMapActivity: props.isMindmapActivitiesChecked ? 1 : 0,
            }),
            ...(props.selectedTags?.length > 0 && { filterByTag: props.selectedTags.map((tag) => tag.label) }),
            ...(props.orderBy.column &&
                props.orderBy.type && { orderByColumn: props.orderBy.column, orderByType: props.orderBy.type }),
            hideActionPlan: props.hideActionPlan.value,
        };
        try {
            const response = await post(reqObj);
            if (response.data.stat === "ok") {
                const downloadUrl = response.data.created;
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.download = "filename.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                toast(response.data.text || "Failed to export Excel file", { type: "success" });
            } else {
                toast(response.data.text || "Failed to export Excel file", { type: "error" });
            }
        } catch (error) {
            toast(error?.response?.data?.text || "Something went wrong", { type: "error" });
        }
    };
    return (
        <div className="d-flex  flex-column-reverse w-100 flex-md-row justify-content-end" style={{ gap: "8px" }}>
            {props.viewType !== "mindmap" && (
                <>
                    {/* <SearchInput
                        id="kt_datatable_search_query"
                        className="activity-search form-control"
                        placeholder="Search by Activity Name"
                        variant="disable-outline-input"
                        value={props.searchQuery}
                        onChange={(event) => props.setSearchQuery(event.target.value)}
                        minLength={2}
                        debounceTimeout={500}
                    /> */}
                    <InputWithIcon
                        leftIcon="search"
                        rightIcon={props.searchInput?.length > 0 ? "x" : null}
                        minLength={1}
                        debounceTimeout={300}
                        onChange={(event) => props.setSearchQuery(event.target.value)}
                        // leftIconClick={handleClearInput}
                        // rightIconClick={handleClearInput}
                        className="w-100 form-control"
                        placeholder={"Search..."}
                        id="kt_datatable_search_query"
                        value={props.searchQuery}
                    />
                    {/* Spacer  */}
                    {/* <div style={{ flex: "1 1 0%", placeSelf: "stretch" }} /> */}
                </>
            )}
            <div className="d-flex mb-4 mb-md-0" style={{ gap: "8px" }}>
                {props.viewType !== "mindmap" && <SortDropdown orderBy={props.orderBy} setOrderBy={props.setOrderBy} />}
                <FilterByAssigneeDropdown />
                <FilterByDropdown />
                <ActivityDrawerFilters />
                {greaterThanTabletSize && (
                    <ButtonWithText
                        variant={props.viewType === "mindmap" ? "checkboxActive" : "checkboxInActive"}
                        onClick={() => props.setViewType("mindmap")}
                        disabled={props.selectedCampaignListFromFilter?.length !== 1}
                        tooltip={
                            props.selectedCampaignListFromFilter?.length !== 1
                                ? "Select only one campaign to enable Mindmap"
                                : "Mind Map"
                        }
                    >
                        <SVG src={toAbsoluteUrl("/media/svg/popleads/mind-map.svg")} />
                    </ButtonWithText>
                )}
                <div className="btn-group btn-group-toggle btn-group-subheader ml-0" data-toggle="buttons">
                    {/* <label className={props.viewType === "board" ? "selectGridBtn active" : "selectGridBtn"}>
                        <input
                            type="radio"
                            name="viewType"
                            id="option1"
                            onChange={() => {
                                props.setViewType("board");
                                saveUserPreference("activity_page", { ...userPreferences, view: "board" });
                            }}
                            autoComplete="off"
                            checked={props.viewType === "board" ? true : false}
                        />
                        <TooltipOverlay placement="top" tooltip={"Board View"}>
                            <SVG src={toAbsoluteUrl("/media/svg/popleads/select_grid_icon.svg")} />
                        </TooltipOverlay>
                    </label>
                    <label className={props.viewType === "table" ? "selectTableBtn active" : "selectTableBtn"}>
                        <input
                            type="radio"
                            name="viewType"
                            id="option2"
                            onChange={() => {
                                props.setViewType("table");
                                saveUserPreference("activity_page", { ...userPreferences, view: "table" });
                            }}
                            autoComplete="off"
                            checked={props.viewType === "table" ? true : false}
                        />
                        <TooltipOverlay placement="bottom" tooltip={"List View"}>
                            <SVG src={toAbsoluteUrl("/media/svg/popleads/select_list_icon.svg")} />
                        </TooltipOverlay>
                    </label> */}
                    <Toggle
                        selected={props.viewType}
                        setSelected={(viewType) => dispatch(activitiesRedux.actions.setViewType(viewType))}
                        options={toggleOptions}
                    />
                </div>
                {/* FOR MOBILE + TABLET */}
                <div className="d-block d-lg-none">
                    <ButtonWithText
                        variant="successInverted"
                        icon="plus"
                        tooltip="New Activity"
                        onClick={() => {
                            props.setShowAddActivityModal(true);
                            props.setIsSubActivity(false);
                        }}
                    />
                </div>
                {/* FOR LG SCREEN AND ABOVE */}
                {props.user.permissions?.DOWNLOAD_EXCEL_FOR_ACTIVITY === 1 && (
                    <ButtonWithText
                        variant="successActive"
                        tooltip="Export as an excel file"
                        onClick={handleExcelClick}
                    >
                        <SVG src={toAbsoluteUrl("/media/svg/popleads/export_excel_icon.svg")} />
                    </ButtonWithText>
                )}
                <div className="d-none d-lg-block">
                    <ButtonWithText
                        variant="successInverted"
                        leftIcon="plus"
                        onClick={() => {
                            props.setShowAddActivityModal(true);
                            props.setIsSubActivity(false);
                        }}
                    >
                        New Activity
                    </ButtonWithText>
                </div>
                {isMobile && !isFromMobileApp && <ChatGptDrawer />}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        viewType: state.activities.viewType,
        isSubActivity: state.activities.isSubActivity,
        selectedCampaignListFromFilter: state.activities.selectedCampaignListFromFilter,
        selectedGroupByOption: state.activities.selectedGroupByOption,
        selectedFilterByOptions: state.activities.selectedFilterByOptions,
        priorityFilter: state.activities.priorityFilter,
        filterByAssignee: state.activities.filterByAssignee,
        filterByCreator: state.activities.filterByCreator,
        searchQuery: state.activities.searchQuery,
        selectedActivityTypes: state.activities.selectedActivityTypes,
        selectedCampaignFilter: state.activities.selectedCampaignFilter,
        selectedGroupBySortOption: state.activities.selectedGroupBySortOption,
        activityDateFilter: state.activities.activityDateFilter,
        isMindmapActionPlanChecked: state.activities.isMindmapActionPlanChecked,
        isMindmapActivitiesChecked: state.activities.isMindmapActivitiesChecked,
        selectedTags: state.activities.selectedTags,
        hideActionPlan: state.activities.hideActionPlan,
        orderBy: state.activities.orderBy,
    };
};

const mapDispatchToProps = {
    ...activitiesRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesActions);
