import CustomSelect from "app/popleads/components/ui-kit/Select";
import React, { useState } from "react";
import { connect } from "react-redux";

function SelectInputComponent() {
    const [selectedOption, setSelectedOption] = useState();
    const options = [
        { label: "Option 1", value: "Option 1" },
        { label: "Option 2", value: "Option 2" },
        { label: "Option 3", value: "Option 3" },
    ];

    return (
        <div className="card card-custom mt-5">
            <div className="card-header">
                <h3 className="card-title">Select Input</h3>
            </div>
            <div className="card-body">
                <h4>Single Selection</h4>
                <CustomSelect
                    options={options}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={true}
                    onChange={(selection) => setSelectedOption(selection)}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                />

                <div className="mt-3">
                    <p>Selected Option: {selectedOption?.value}</p>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SelectInputComponent);
