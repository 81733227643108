import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import Select, { components } from "react-select";
import { colors } from "./colors";
import styled from "styled-components";

const StyledDownIconWrapper = styled.div`
    display: flex;
    align-items: center;
    color: ${colors.neutral[900]};
    margin-right: 4px;
`;

const StyledCancelIconWrapper = styled.div`
    display: flex;
    align-items: center;
    color: ${colors.primary[900]};
    margin-right: 8px;
`;

const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid ${colors.neutral[500]};
    box-shadow: 0px 3px 8px #49698c1a;
    border-radius: 4px;
    opacity: 1;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease;

    &:checked {
        background-color: ${colors.primary[900]};
        border-color: ${colors.primary[900]};
    }

    &:checked::after {
        content: "✔";
        color: white;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        border-color: ${colors.primary[700]};
    }

    position: relative;
`;

//TODO: search edilirken girilen metnin seçilmesi - https://codesandbox.io/p/sandbox/react-select-examples-6picqk?file=%2Fsrc%2Fhighlight-searched.tsx%3A43%2C46

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        border: state.isFocused ? `2px solid ${colors.primary[900]}` : `1px solid ${colors.neutral[300]}`,
        borderRadius: "8px",
        paddingTop: "2px",
        paddingBottom: "2px",
        height: "40px",
        fontSize: "15px",
    }),
    placeholder: (provided) => ({
        ...provided,
        color: colors.neutral[500],
        //TODO: tekin-font eşleştirmesini yapamadık eklenmesi gerekiyor
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    }),
    option: (provided) => ({
        ...provided,
        color: colors.neutral[900],
        backgroundColor: "#fff",
        "&:hover": {
            backgroundColor: "#F6F8F9", //TODO: tekin-renk eşleştirmesini yapamadık eklenmesi gerekiyor
            color: colors.neutral[900],
        },
    }),
};

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <div className="d-flex justify-content-between align-items-center">
                    <label>{props.label}</label>
                    <StyledCheckbox checked={props.isSelected} onChange={() => null} />
                </div>
            </components.Option>
        </div>
    );
};

const CustomSelect = forwardRef(({ classNamePrefix = "customSelectInput", value, ...props }, ref) => {
    const [selectedValue, setSelectedValue] = useState(null);
    const selectRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            selectRef,
        }),
        [selectRef]
    );

    const handleChange = (value) => {
        setSelectedValue(value);

        if (props.onChange) {
            props.onChange(value);
        }
    };
    return (
        <Select
            ref={selectRef}
            isClearable={props.isClearable}
            isSearchable={props.isSearchable}
            {...props}
            styles={customStyles}
            value={selectedValue}
            onChange={handleChange}
            options={props.options}
            placeholder={props.placeholder}
            components={{
                DropdownIndicator: () => (
                    // <div aria-label="chevron-down" role="img">
                    //     <SVG src={toAbsoluteUrl("/media/svg/icons/General/chevron-down.svg")} />
                    // </div>
                    <StyledDownIconWrapper aria-label="chevron-down" role="img">
                        <span className="material-icons">keyboard_arrow_down</span>
                    </StyledDownIconWrapper>
                ),
                ClearIndicator: ({ innerProps: { ref, ...restInnerProps } }) => {
                    return (
                        <StyledCancelIconWrapper {...restInnerProps} ref={ref} aria-label="x" role="img">
                            <span className="material-icons-outlined">cancel</span>
                        </StyledCancelIconWrapper>
                    );
                },
                Option,
            }}
        />
    );
});

export default CustomSelect;
