import React from "react";
import PartnerFilterDrawer from "../drawers/PartnerFilterDrawer";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/popleads/modules/accounts/redux/accountsRedux";
import PartnerDateDropdown from "../components/PartnerDateDropdown";
import InputWithIcon from "app/popleads/components/ui-kit/Input";
import ChatGptDrawer from "app/popleads/components/Chat/ChatGptDrawer";

const PartnerAccountsActions = () => {
    const { searchInput, partnerSelectedDate } = useSelector((state) => state.accounts);

    const dispatch = useDispatch();

    const handleSearch = (value) => {
        dispatch(actions.search(value));
    };

    const handleClearInput = () => {
        handleSearch("");
    };

    return (
        <div className="d-flex align-items-center" style={{ gap: "8px" }}>
            <InputWithIcon
                leftIcon="search"
                rightIcon={searchInput?.length > 0 ? "x" : null}
                minLength={1}
                debounceTimeout={300}
                onChange={(event) => handleSearch(event.target.value)}
                leftIconClick={handleClearInput}
                rightIconClick={handleClearInput}
                className="w-100 form-control"
                placeholder={"Search..."}
                id="kt_datatable_search_query"
                value={searchInput}
            />
            <PartnerDateDropdown
                selectedDate={partnerSelectedDate}
                setSelectedDate={(date) => dispatch(actions.setPartnerSelectedDate(date))}
            />
            <PartnerFilterDrawer />
            <div
                style={{
                    width: "40px",
                    height: "40px",
                    overflow: "hidden",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "40px",
                    minHeight: "40px",
                }}
            >
                <ChatGptDrawer className={"m-0 btn-borderless"} />
            </div>
        </div>
    );
};

export default PartnerAccountsActions;
