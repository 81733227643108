import React from "react";
import { connect } from "react-redux";
import * as appRedux from "../redux/appRedux";
import * as reportsRedux from "../../modules/reports/redux/reportsRedux";
import moment from "moment";
import * as util from "../functions/util";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import SVG from "react-inlinesvg";

class Pdf extends React.Component {
    createURL() {
        const baseURL = window.location.href;
        let path = window.location.pathname.split("/");
        let name = path[path.length - 1] + "-" + moment().format("DD-MM-YYYY") + ".pdf";
        let filters = "";
        if (this.props.selectedDepartment) {
            let selected = [];
            for (let i = 0; i < this.props.selectedDepartment.length; i++) {
                let obj = {
                    label: this.props.selectedDepartment[i].label,
                    value: this.props.selectedDepartment[i].value,
                };

                obj.label = obj.label.replaceAll("&", "xxxampxxx");
                obj.label = obj.label.replaceAll("?", "xxxqmaxxx");
                obj.label = obj.label.replaceAll("=", "xxxequxxx");
                obj.label = obj.label.replaceAll(",", "xxxcomxxx");
                obj.label = obj.label.replaceAll(".", "xxxdotxxx");
                obj.label = obj.label.replaceAll('"', "xxxdquxxx");
                obj.label = obj.label.replaceAll("'", "xxxsquxxx");
                selected.push(obj);
            }
            filters += "&department=" + JSON.stringify(selected);
        }
        if (this.props.selectedDate) {
            const dateObj = {};
            dateObj["startDate"] = moment(this.props.selectedDate.startDate, "DD/MM/YYYY").format("yyyy-MM-DD");
            dateObj["endDate"] = moment(this.props.selectedDate.endDate, "DD/MM/YYYY").format("yyyy-MM-DD");
            dateObj["predefinedTag"] = this.props.selectedDate.predefinedTag;
            dateObj["dateString"] = this.props.selectedDate.dateString;
            filters += "&date=" + JSON.stringify(dateObj);
        }
        if (this.props.selectedEmployee) {
            filters += "&employee=" + JSON.stringify(this.props.selectedEmployee);
        }
        if (this.props.selectedCampaignType) {
            filters += "&campaignType=" + this.props.selectedCampaignType;
        }
        if (this.props.dataAndKeyOfLineGraph && this.props.dataAndKeyOfLineGraph.key) {
            filters += "&lineKey=" + this.props.dataAndKeyOfLineGraph.key;
        }
        if (this.props.keyOfAreaGraph) {
            filters += "&areaKey=" + this.props.keyOfAreaGraph;
        }
        if (this.props.selectedInquiryType) {
            let selected = [];
            for (let i = 0; i < this.props.selectedInquiryType.length; i++) {
                let obj = {
                    label: this.props.selectedInquiryType[i].label,
                    value: this.props.selectedInquiryType[i].value,
                };

                obj.label = obj.label.replaceAll("&", "xxxampxxx");
                obj.label = obj.label.replaceAll("?", "xxxqmaxxx");
                obj.label = obj.label.replaceAll("=", "xxxequxxx");
                obj.label = obj.label.replaceAll(",", "xxxcomxxx");
                obj.label = obj.label.replaceAll(".", "xxxdotxxx");
                obj.label = obj.label.replaceAll('"', "xxxdquxxx");
                obj.label = obj.label.replaceAll("'", "xxxsquxxx");
                selected.push(obj);
            }
            filters += "&inquiry=" + JSON.stringify(selected);
        }
        if (this.props.selectedFranchise) {
            let selected = [];
            for (let i = 0; i < this.props.selectedFranchise.length; i++) {
                let obj = {
                    label: this.props.selectedFranchise[i].label,
                    value: this.props.selectedFranchise[i].value,
                };

                obj.label = obj.label.replaceAll("&", "xxxampxxx");
                obj.label = obj.label.replaceAll("?", "xxxqmaxxx");
                obj.label = obj.label.replaceAll("=", "xxxequxxx");
                obj.label = obj.label.replaceAll(",", "xxxcomxxx");
                obj.label = obj.label.replaceAll(".", "xxxdotxxx");
                obj.label = obj.label.replaceAll('"', "xxxdquxxx");
                obj.label = obj.label.replaceAll("'", "xxxsquxxx");
                selected.push(obj);
            }
            filters += "&franchise=" + JSON.stringify(selected);
        }

        if (this.props.selectedCountry) {
            let selected = [];
            for (let i = 0; i < this.props.selectedCountry.length; i++) {
                let obj = this.props.selectedCountry[i];

                obj = obj.replaceAll("&", "xxxampxxx");
                obj = obj.replaceAll("?", "xxxqmaxxx");
                obj = obj.replaceAll("=", "xxxequxxx");
                obj = obj.replaceAll(",", "xxxcomxxx");
                obj = obj.replaceAll(".", "xxxdotxxx");
                obj = obj.replaceAll('"', "xxxdquxxx");
                obj = obj.replaceAll("'", "xxxsquxxx");
                selected.push(obj);
            }
            filters += "&asideCountry=" + JSON.stringify(selected);
        }

        if (this.props.selectedAccount) {
            let selected = [];
            for (let i = 0; i < this.props.selectedAccount.length; i++) {
                let obj = this.props.selectedAccount[i];

                obj = obj.replaceAll("&", "xxxampxxx");
                obj = obj.replaceAll("?", "xxxqmaxxx");
                obj = obj.replaceAll("=", "xxxequxxx");
                obj = obj.replaceAll(",", "xxxcomxxx");
                obj = obj.replaceAll(".", "xxxdotxxx");
                obj = obj.replaceAll('"', "xxxdquxxx");
                obj = obj.replaceAll("'", "xxxsquxxx");
                selected.push(obj);
            }
            filters += "&asideAccount=" + JSON.stringify(selected);
        }

        if (this.props.selectedIndustry) {
            let selected = [];
            for (let i = 0; i < this.props.selectedIndustry.length; i++) {
                let obj = this.props.selectedIndustry[i];

                obj = obj.replaceAll("&", "xxxampxxx");
                obj = obj.replaceAll("?", "xxxqmaxxx");
                obj = obj.replaceAll("=", "xxxequxxx");
                obj = obj.replaceAll(",", "xxxcomxxx");
                obj = obj.replaceAll(".", "xxxdotxxx");
                obj = obj.replaceAll('"', "xxxdquxxx");
                obj = obj.replaceAll("'", "xxxsquxxx");
                selected.push(obj);
            }
            filters += "&asideIndustry=" + JSON.stringify(selected);
        }

        if (this.props.selectedPortfolio) {
            let selected = [];
            for (let i = 0; i < this.props.selectedPortfolio.length; i++) {
                let obj = this.props.selectedPortfolio[i];

                obj = obj.replaceAll("&", "xxxampxxx");
                obj = obj.replaceAll("?", "xxxqmaxxx");
                obj = obj.replaceAll("=", "xxxequxxx");
                obj = obj.replaceAll(",", "xxxcomxxx");
                obj = obj.replaceAll(".", "xxxdotxxx");
                obj = obj.replaceAll('"', "xxxdquxxx");
                obj = obj.replaceAll("'", "xxxsquxxx");
                selected.push(obj);
            }
            filters += "&asidePortfolio=" + JSON.stringify(selected);
        }

        const downloadUrl = baseURL.includes("localhost")
            ? "https://localhost:8443/PopLeads"
            : window.location.protocol + "//" + window.location.host;
        let authToken = util.getCookieValue(util.getCookieName());
        let url =
            downloadUrl +
            "/api/ws/download/" +
            name +
            "?token=" +
            authToken +
            "&url=" +
            baseURL +
            "/?sid=" +
            authToken +
            encodeURIComponent(filters);
        window.open(url, "_blank");
        return false;
    }

    render() {
        if (this.props.pdfDivId) {
            return (
                <div className="ml-2 mr-0">
                    <ButtonWithText
                        variant="dangerActive"
                        onClick={() => {
                            this.createURL();
                        }}
                    >
                        <SVG src="/media/svg/popleads/pdf.svg" />
                    </ButtonWithText>
                </div>
                //<img className="pdf-icon" onClick={() => { this.createURL() }} src={toAbsoluteUrl("/media/popleads/pdf.png")} alt="PDF" title="Save as PDF" />
            );
        }
        return <div></div>;
    }
}

const mapDispatchToProps = {
    ...appRedux.actions,
    ...reportsRedux.actions,
};

const mapStateToProps = (state) => {
    return {
        pdfDivId: state.app.pdfDivId,
        pdfScaleSize: state.app.pdfScaleSize,
        pdfDownloadName: state.app.pdfDownloadName,
        isPdfLoading: state.app.isPdfLoading,
        selectedEmployee: state.reports.selectedEmployee,
        selectedDepartment: state.reports.selectedDepartment,
        selectedDate: state.reports.selectedDate,
        selectedInquiryType: state.reports.selectedInquiryType,
        selectedFranchise: state.reports.selectedFranchise,
        authToken: state.auth.authToken,
        selectedCampaignType: state.reports.selectedCampaignType,
        dataAndKeyOfLineGraph: state.reports.dataAndKeyOfLineGraph,
        keyOfAreaGraph: state.reports.keyOfAreaGraph,
        selectedCountry: state.reports.selectedCountry_Aside,
        selectedAccount: state.reports.selectedAccount_Aside,
        selectedIndustry: state.reports.selectedIndustry_Aside,
        selectedPortfolio: state.reports.selectedPortfolio_Aside,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pdf);
