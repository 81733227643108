import React, { useState } from "react";
import { connect } from "react-redux";
import ButtonWithText from "app/popleads/components/ui-kit/Button";

function ButtonComponent() {
    const [count, setCount] = useState(0);

    return (
        <div className="card card-custom mt-5">
            <div className="card-header">
                <h3 className="card-title">Button</h3>
            </div>
            <div className="card-body">
                <h4>Primary Button</h4>
                <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", marginBottom: "1rem" }}>
                    <ButtonWithText variant="primary" onClick={() => setCount(count + 1)}>
                        Primary Button
                    </ButtonWithText>
                    <ButtonWithText variant="primary" leftIcon={"check"} onClick={() => setCount(count + 1)}>
                        Primary Button
                    </ButtonWithText>
                    <ButtonWithText variant="primary" rightIcon={"check"} onClick={() => setCount(count + 1)}>
                        Primary Button
                    </ButtonWithText>
                    <ButtonWithText
                        variant="primary"
                        leftIcon={"check"}
                        rightIcon={"x"}
                        onClick={() => setCount(count + 1)}
                    >
                        Primary Button
                    </ButtonWithText>
                    <ButtonWithText
                        variant="primary"
                        icon={"check"}
                        onClick={() => setCount(count + 1)}
                    ></ButtonWithText>
                </div>

                <h4>Primary Button Inverted</h4>
                <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", marginBottom: "1rem" }}>
                    <ButtonWithText variant="primaryInverted" onClick={() => setCount(count + 1)}>
                        Primary Button Inverted
                    </ButtonWithText>
                    <ButtonWithText variant="primaryInverted" leftIcon={"check"} onClick={() => setCount(count + 1)}>
                        Primary Button Inverted
                    </ButtonWithText>
                    <ButtonWithText variant="primaryInverted" rightIcon={"check"} onClick={() => setCount(count + 1)}>
                        Primary Button Inverted
                    </ButtonWithText>
                    <ButtonWithText
                        variant="primaryInverted"
                        leftIcon={"check"}
                        rightIcon={"x"}
                        onClick={() => setCount(count + 1)}
                    >
                        Primary Button Inverted
                    </ButtonWithText>
                    <ButtonWithText
                        variant="primaryInverted"
                        icon={"check"}
                        onClick={() => setCount(count + 1)}
                    ></ButtonWithText>
                </div>

                <h4>Success Button</h4>
                <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", marginBottom: "1rem" }}>
                    <ButtonWithText variant="success" onClick={() => setCount(count + 1)}>
                        Success Button
                    </ButtonWithText>
                    <ButtonWithText variant="success" leftIcon={"check"} onClick={() => setCount(count + 1)}>
                        Success Button
                    </ButtonWithText>
                    <ButtonWithText variant="success" rightIcon={"check"} onClick={() => setCount(count + 1)}>
                        Success Button
                    </ButtonWithText>
                    <ButtonWithText
                        variant="success"
                        leftIcon={"check"}
                        rightIcon={"x"}
                        onClick={() => setCount(count + 1)}
                    >
                        Success Button
                    </ButtonWithText>
                    <ButtonWithText
                        variant="success"
                        icon={"check"}
                        onClick={() => setCount(count + 1)}
                    ></ButtonWithText>
                </div>

                <h4>Success Button Inverted</h4>
                <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", marginBottom: "1rem" }}>
                    <ButtonWithText variant="successInverted" onClick={() => setCount(count + 1)}>
                        Success Button Inverted
                    </ButtonWithText>
                    <ButtonWithText variant="successInverted" leftIcon={"check"} onClick={() => setCount(count + 1)}>
                        Success Button Inverted
                    </ButtonWithText>
                    <ButtonWithText variant="successInverted" rightIcon={"check"} onClick={() => setCount(count + 1)}>
                        Success Button Inverted
                    </ButtonWithText>
                    <ButtonWithText
                        variant="successInverted"
                        leftIcon={"check"}
                        rightIcon={"x"}
                        onClick={() => setCount(count + 1)}
                    >
                        Success Button Inverted
                    </ButtonWithText>
                    <ButtonWithText
                        variant="successInverted"
                        icon={"check"}
                        onClick={() => setCount(count + 1)}
                    ></ButtonWithText>
                </div>

                <h4>Danger Button</h4>
                <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", marginBottom: "1rem" }}>
                    <ButtonWithText variant="danger" onClick={() => setCount(count + 1)}>
                        Danger Button
                    </ButtonWithText>
                    <ButtonWithText variant="danger" leftIcon={"check"} onClick={() => setCount(count + 1)}>
                        Danger Button
                    </ButtonWithText>
                    <ButtonWithText variant="danger" rightIcon={"check"} onClick={() => setCount(count + 1)}>
                        Danger Button
                    </ButtonWithText>
                    <ButtonWithText
                        variant="danger"
                        leftIcon={"check"}
                        rightIcon={"x"}
                        onClick={() => setCount(count + 1)}
                    >
                        Danger Button
                    </ButtonWithText>
                    <ButtonWithText
                        variant="danger"
                        icon={"check"}
                        onClick={() => setCount(count + 1)}
                    ></ButtonWithText>
                </div>

                <h4>Danger Button Inverted</h4>
                <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", marginBottom: "1rem" }}>
                    <ButtonWithText variant="dangerInverted" onClick={() => setCount(count + 1)}>
                        Danger Button Inverted
                    </ButtonWithText>
                    <ButtonWithText variant="dangerInverted" leftIcon={"check"} onClick={() => setCount(count + 1)}>
                        Danger Button Inverted
                    </ButtonWithText>
                    <ButtonWithText variant="dangerInverted" rightIcon={"check"} onClick={() => setCount(count + 1)}>
                        Danger Button Inverted
                    </ButtonWithText>
                    <ButtonWithText
                        variant="dangerInverted"
                        leftIcon={"check"}
                        rightIcon={"x"}
                        onClick={() => setCount(count + 1)}
                    >
                        Danger Button Inverted
                    </ButtonWithText>
                    <ButtonWithText
                        variant="dangerInverted"
                        icon={"check"}
                        onClick={() => setCount(count + 1)}
                    ></ButtonWithText>
                </div>

                <h4>Warning Button</h4>
                <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", marginBottom: "1rem" }}>
                    <ButtonWithText variant="warning" onClick={() => setCount(count + 1)}>
                        Warning Button
                    </ButtonWithText>
                    <ButtonWithText variant="warning" leftIcon={"check"} onClick={() => setCount(count + 1)}>
                        Warning Button
                    </ButtonWithText>
                    <ButtonWithText variant="warning" rightIcon={"check"} onClick={() => setCount(count + 1)}>
                        Warning Button
                    </ButtonWithText>
                    <ButtonWithText
                        variant="warning"
                        leftIcon={"check"}
                        rightIcon={"x"}
                        onClick={() => setCount(count + 1)}
                    >
                        Warning Button
                    </ButtonWithText>
                    <ButtonWithText
                        variant="warning"
                        icon={"check"}
                        onClick={() => setCount(count + 1)}
                    ></ButtonWithText>
                </div>

                <h4>Warning Button Inverted</h4>
                <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", marginBottom: "1rem" }}>
                    <ButtonWithText variant="warningInverted" onClick={() => setCount(count + 1)}>
                        Warning Button Inverted
                    </ButtonWithText>
                    <ButtonWithText variant="warningInverted" leftIcon={"check"} onClick={() => setCount(count + 1)}>
                        Warning Button Inverted
                    </ButtonWithText>
                    <ButtonWithText variant="warningInverted" rightIcon={"check"} onClick={() => setCount(count + 1)}>
                        Warning Button Inverted
                    </ButtonWithText>
                    <ButtonWithText
                        variant="warningInverted"
                        leftIcon={"check"}
                        rightIcon={"x"}
                        onClick={() => setCount(count + 1)}
                    >
                        Warning Button Inverted
                    </ButtonWithText>
                    <ButtonWithText
                        variant="warningInverted"
                        icon={"check"}
                        onClick={() => setCount(count + 1)}
                    ></ButtonWithText>
                </div>

                <div>
                    <p>Click Count: {count}</p>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonComponent);
