import React, { useState } from "react";
import styled, { css } from "styled-components";
import FeatherIcon from "feather-icons-react";
import { DebounceInput } from "react-debounce-input";
import { colors } from "./colors";

const variantStyles = {
    normal: css`
        border-color: ${colors.neutral[500]};
    `,
    focus: css`
        border-color: ${colors.primary[900]};
        color: ${colors.primary[900]};
        border-width: 2px;
        padding: 10px 14px;
    `,
    filled: css`
        color: ${colors.primary[900]};
    `,
    disabled: css`
        border-color: ${colors.neutral[300]};
        color: ${colors.neutral[300]};
        background-color: #f6f8f9;
    `,
    error: css`
        border-color: #e74c3c;
        color: #e74c3c;
    `,
};

const InputContainer = styled.div`
    background-color: #fff;
    display: flex;
    align-items: center;
    border: 1px solid ${colors.neutral[500]};
    border-radius: 8px;
    padding: 10px 15px;
    height: 41px;
    color: ${colors.neutral[500]};
    text-color: ${colors.neutral[500]};

    ${({ variant }) => variantStyles[variant]}
`;

const StyledDebounceInput = styled(DebounceInput)`
    border: none;
    outline: none;
    flex: 1;
    font-size: 16px;
    padding: 0 8px;
    background-color: transparent;
    width: 100%;

    &::placeholder {
        color: ${colors.neutral[500]};
    }

    &:disabled {
        border-color: ${colors.neutral[300]};
        color: ${colors.neutral[300]};
        background-color: #f6f8f9;

        &::placeholder {
            color: ${colors.neutral[300]};
        }
    }
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 20px;
    height: 20px;
`;

export const Seperator = styled.div`
    width: 1px;
    height: 100%;
    margin-left: 10px;
    background-color: ${({ isDisabled }) => (isDisabled ? `${colors.neutral[500]}` : `${colors.neutral[300]}`)};
`;

const InputWithIcon = ({
    leftIcon,
    rightIcon,
    leftIconClick,
    value,
    onChange,
    placeholder,
    rightIconClick,
    disabled,
    debounceTime = 300,
    error = false,
}) => {
    const [isFocused, setIsFocused] = useState(false);

    let variant = "normal";

    if (disabled) {
        variant = "disabled";
    } else if (error) {
        variant = "error";
    } else if (isFocused) {
        variant = value ? "filled" : "focus";
    } else if (value) {
        variant = "filled";
    }

    return (
        <InputContainer variant={variant} disabled={disabled}>
            {leftIcon && (
                <>
                    <IconContainer
                        aria-label="search-click"
                        data-testid="left-icon"
                        className="inputLeftIcon"
                        onClick={leftIconClick}
                    >
                        <FeatherIcon aria-label="search" icon={leftIcon} size={20} />
                    </IconContainer>
                    <Seperator />
                </>
            )}
            <StyledDebounceInput
                minLength={2}
                debounceTimeout={debounceTime}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                disabled={disabled}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />

            <IconContainer
                aria-label="x-click"
                data-testid="right-icon"
                className="inputRightIcon"
                onClick={rightIconClick}
            >
                {rightIcon && <FeatherIcon aria-label="x" icon={rightIcon} size={20} />}
            </IconContainer>
        </InputContainer>
    );
};

export default InputWithIcon;
