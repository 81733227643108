import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "app/popleads/modules/campaigns/redux/campaignsRedux";
import CourseActionDropdown from "../components/CourseActionDropdown";
import Select from "app/popleads/app/partials/Select";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import SVG from "react-inlinesvg";
import ChatGptDrawer from "app/popleads/components/Chat/ChatGptDrawer";

const CourseDetailActions = () => {
    const { user } = useSelector((state) => state.auth);
    const { isOpenCourseEditButton, language } = useSelector((state) => state.campaigns);

    const dispatch = useDispatch();

    if (user?.permissions?.CAMPAIGNTEMPLATE_MANAGER !== 1) return null;

    return (
        <div className="d-flex align-items-center" style={{ gap: "8px" }}>
            {!isOpenCourseEditButton ? (
                <>
                    <ButtonWithText
                        leftIcon="edit"
                        variant="successInverted"
                        onClick={() => dispatch(actions.toggleCourseEditButton(true))}
                    >
                        Edit
                    </ButtonWithText>
                    <CourseActionDropdown />
                    <ButtonWithText
                        variant="successActive"
                        onClick={() => dispatch(actions.setCoursesDetailExcel(true))}
                    >
                        <SVG src="/media/svg/popleads/export_excel_icon.svg" />
                    </ButtonWithText>
                </>
            ) : (
                <>
                    <Select
                        placeholder={"Language"}
                        className="customSelectInput--no-wrap "
                        value={language}
                        defaultValue={{ value: "en", label: "English" }}
                        isSearchable={false}
                        options={[
                            { value: "en", label: "English" },
                            { value: "ar", label: "Arabic" },
                        ]}
                        name="language"
                        onChange={(selected) => dispatch(actions.setLanguage(selected))}
                    />
                    <ButtonWithText
                        variant="neutralInverted"
                        onClick={() => dispatch(actions.toggleCourseEditButton(false))}
                    >
                        Done
                    </ButtonWithText>
                </>
            )}
            <div
                style={{
                    width: "40px",
                    height: "40px",
                    overflow: "hidden",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "40px",
                    minHeight: "40px",
                }}
            >
                <ChatGptDrawer className={"m-0 btn-borderless"} />
            </div>
        </div>
    );
};

export default CourseDetailActions;
